import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Heading, Stack } from '@chakra-ui/react';

import authModule from 'shared/src/modules/auth';

import OktaWidget from 'web-react-ui/src/components/auth/OktaSignIn';
import IsLoggedInRedirect from 'web-react-ui/src/components/login/IsLoggedInRedirect';

import Terms from '../siteFooter/Terms';
import client from '../../services/client';

const LoginContainer = (): JSX.Element => {
  const isLoggedIn = useSelector(state => authModule.selectors.getIsLoggedIn(state));
  if (isLoggedIn) return <IsLoggedInRedirect />;
  return (
    <Box mx='auto'>
      <Stack spacing={1}>
        <Heading mb={0} as='h1' fontWeight='regular' fontSize={21}>Platform Admin</Heading>
        <Heading mb={0} as='h1' fontWeight='regular' fontSize={21}>Log in</Heading>
      </Stack>
      <OktaWidget client={client} />
      <Box mt={6} width='100%'>
        <Terms />
      </Box>
    </Box>
  );
};

export default LoginContainer;
