export default {
  value: 'loyalty',
  label: 'Loyalty',
  itemPrices: [
    {
      value: 'monthly',
      label: 'Monthly',
      plan: {
        chargebeePlanId: 'loyalty-local-monthly-cad',
        currencyCode: 'CAD',
        defaultPrice: 99
      }
    },
    {
      value: 'annual',
      label: 'Annually',
      plan: {
        chargebeePlanId: 'loyalty-local-annual-cad',
        currencyCode: 'CAD',
        defaultPrice: 990
      }
    }
  ]
};
