/* eslint-disable arrow-body-style */
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { connect } from 'react-redux';

import usePromise from 'shared/src/hooks/usePromise';

import List from 'web-react-ui/src/components/elements/List';
import ErrorMessage from 'web-react-ui/src/components/entities/ErrorMessage';
import UserItem from 'web-react-ui/src/components/entities/UserItem';
import View from 'web-react-ui/src/components/layout/View';
import PagedList from 'web-react-ui/src/components/pagedList/PagedList';
import InviteUserForm from 'web-react-ui/src/components/users/InviteUserForm';
import IfPermissions from 'web-react-ui/src/components/utils/IfPermissions';
import WaitFor from 'web-react-ui/src/data/WaitFor';
import businessesModule from '../../modules/businesses';

import client from '../../services/client';
import confirm from '../../services/confirm';

const mapState = state => ({
  business: businessesModule.selectors.business.getData(state)
});

const actualInviteUser = ({
  business, email, name, roleId
}) => {
  return client
    .businesses.for(business.id)
    .users
    .invite({ email, name }, { id: roleId }, true);
};

const removeUser = ({ business, user }) => {
  return client
    .businesses.for(business.id)
    .users.for(user.id)
    .remove();
};

const AdminBusinessUsers = ({ business }) => {
  const fetchUsers = useCallback(
    () => {
      return client
        .businesses.for(business.id)
        .users.searchUsers(
          {
            context: ['B', 'BL']
          },
          {
            fields: ['roleIds', 'name', 'id', 'email', 'locationIds']
          }
        );
    },
    [client]
  );

  const [refetchKey, setRefetchKey] = React.useState(0);
  const refreshList = useCallback(() => setRefetchKey(key => key + 1), [setRefetchKey]);

  const [inviteUser] = usePromise(
    ({ email, name, roleId }) => {
      return actualInviteUser({
        business, email, name, roleId
      })
        .then(refreshList);
    },
    [business, refreshList]
  );

  const [confirmRemoveUser, isRemoving, , removeError] = usePromise(
    async (__, { context: user }) => {
      if (confirm(`Are you sure you want to remove access for ${user.email}?`)) {
        await removeUser({ business, user });
        refreshList();
      }
    },
    [business, refreshList]
  );

  const actions = [
    {
      label: 'Remove User',
      negative: true,
      action: confirmRemoveUser
    }
  ];

  return (
    <View>
      <View.Section>
        <h1 className="mt0">Users</h1>
      </View.Section>
      <View.Section className="mt3">
        <PagedList fetchList={fetchUsers} params={{ refetchKey }}>
          {({ items, loading }) => (
            <WaitFor waitFor={!loading && !isRemoving}>
              <List celled selection>
                {_.map(
                  items,
                  user => (<UserItem user={user} actions={actions} actionContext={user} key={user.id} />)
                )}
              </List>
              <ErrorMessage error={removeError} />
            </WaitFor>
          )}
        </PagedList>
        <IfPermissions permissions={['business.promote']} context={{ businessId: business.id }}>
          <div>
            <h3>Invite</h3>
            <InviteUserForm
              dashboard
              onSubmit={inviteUser}
              type="business"
            />
          </div>
        </IfPermissions>
      </View.Section>
    </View>
  );
};

AdminBusinessUsers.propTypes = {
  business: PropTypes.object
};

export default connect(mapState)(props => <AdminBusinessUsers {...props} />);
