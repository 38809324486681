/* eslint class-methods-use-this:0 */
import React from 'react';
import CreateBusinessView from '../businesses/create/CreateBusinessView';


const AdminAddBusinessView = () => {
  return <CreateBusinessView />;
};

export default AdminAddBusinessView;
