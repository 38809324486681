/* eslint jsx-a11y/accessible-emoji:0 */
import PropTypes from 'prop-types';
import React from 'react';

import BigModal from 'web-react-ui/src/BigModal/BigModal';
import Message from 'web-react-ui/src/components/collections/Message';
import Button from 'web-react-ui/src/components/elements/Button';
import Divider from 'web-react-ui/src/components/elements/Divider';
import View from 'web-react-ui/src/components/layout/View';
import WaitFor from 'web-react-ui/src/data/WaitFor';
import Map from 'web-react-ui/src/map/Map';
import {
  Field,
  FormSpy
} from 'web-react-ui/src/reactFinalForm';
import {
  FieldError,
  TextField
} from 'web-react-ui/src/reactFinalForm/fields';
import FieldLabel from 'web-react-ui/src/reactFinalForm/fields/FieldLabel';
import FormSubmit from 'web-react-ui/src/reactFinalForm/FormSubmit';
import SimpleForm from 'web-react-ui/src/reactFinalForm/SimpleForm';
import {
  compose,
  max,
  required,
  slug
} from 'web-react-ui/src/reactFinalForm/validators';
import wrapSubmit from 'web-react-ui/src/reactFinalForm/wrapSubmit';
import AddressOsmIdSearch from '../AddressOsmIdSearch';

import AddressSearch from '../AddressSearch';


const AdminBusinessAddLocationView = (props) => {
  const {
    business, onSubmit, onCancel, updateOsmAddress, updatingAddress
  } = props;
  return (
    <BigModal open={true}>
      <BigModal.Contents>
        <BigModal.Header className='p1'>
          <BigModal.CloseButton floated='right' onClick={onCancel}/>
        </BigModal.Header>
        <BigModal.Body>
          <SimpleForm onSubmit={wrapSubmit(onSubmit)}>
            <View>
              <View.Section narrow>
                <View.Section>
                  <h6 className='mb0'>Adding {business.name}</h6>
                  <h1 className='mt0'>Add Location</h1>
                </View.Section>
                <View.Section>
                  <TextField
                    wrapLabel
                    name='name'
                    placeholder='Location Name'
                    validate={compose(max(64), required)}
                  />
                  <TextField wrapLabel name='slug' placeholder='Slug' validate={slug}/>
                  <TextField name='url' label='Url' type='url' validate={max(512)}/>
                  <TextField name='phone' label='Phone Number' type='tel' validate={max(35)}/>

                </View.Section>
                <View.Section>
                  <h2>Address</h2>

                  <div className="field">
                    <Field name='address' validate={required}>
                      {({ input }) => (
                        <label>
                          Find Address
                          <AddressSearch placeholder='Search for address...' {...input} />
                          <FieldError name='address'/>
                        </label>
                      )}
                    </Field>

                    <Divider horizontal>OR</Divider>

                    <Field name='address'>
                      {({ input }) => (
                        <FieldLabel label="OSM URL" wrapLabel>
                          <AddressOsmIdSearch {...input} placeholder='https://www.openstreetmap.org/node/6910530351'/>
                          <FieldError name='address'/>
                        </FieldLabel>
                      )}
                    </Field>
                  </div>

                  <FormSpy subscription={{ values: true }}>
                    {({ values }) => {
                      if (!values.address) return null;
                      return (<WaitFor waitFor={!updatingAddress} wrapContents>
                        <TextField name="address.country" label="Country"/>
                        <TextField name="address.state" label="Province"/>
                        <TextField name="address.postalcode" label="Postal Code"/>
                        <TextField name="address.city" label="City"/>
                        <TextField name="address.street" label="Street"/>
                        <TextField name="address.streetAddress" label="Address"/>
                        <TextField name="address.suite" label="Unit"/>
                        <Button type='button' onClick={() => updateOsmAddress(values.address)}>Update in OSM</Button>
                        <Message info>
                          This updates OSM so please don&apos;t go nuts.
                          Also, you&apos;ll need to reload the address manually for now. 😔
                        </Message>
                      </WaitFor>);
                    }}
                  </FormSpy>


                </View.Section>
                <FormSpy subscription={{ values: true }}>
                  {({ values }) => {
                    const address = values.address;
                    const centre = (address && address.centre && [address.centre.lat, address.centre.long]);
                    return (
                      <div>
                        {address && (
                          <View.Section divided>
                            Confirm Location
                            {!address.isComplete && (
                              <Message error size='mini'>
                                No street address. Location will not work 😢
                              </Message>
                            )}
                            <p className='pt2'>
                              {address.suite}
                              {address.streetAddress}
                              {address.street}<br/>
                              {address.city}, {address.state}
                            </p>
                            {centre && (
                              <Map
                                initialCenter={centre}
                                initialZoom={15}
                                markers={[{ center: centre }]}
                              />
                            )}
                            <p className='pt2 caption tar'>
                              Incorrect?
                              <a href={address.osmEditUrl} target='_blank' rel='noopener noreferrer'>Correct on OSM</a>
                            </p>
                          </View.Section>
                        )}
                        {!address && (
                          <View.Section divided>
                            Confirm Location
                            <p className='pt2'>Find & select address above..</p>
                          </View.Section>
                        )}
                        <View.Section className='flex'>
                          <FormSubmit label='Add Location' style={{ marginLeft: 'auto' }}/>
                        </View.Section>
                      </div>
                    );
                  }}
                </FormSpy>
              </View.Section>
            </View>
          </SimpleForm>
        </BigModal.Body>
      </BigModal.Contents>
    </BigModal>
  );
};
AdminBusinessAddLocationView.propTypes = {
  business: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  updateOsmAddress: PropTypes.func,
  updatingAddress: PropTypes.bool
};

export default AdminBusinessAddLocationView;
