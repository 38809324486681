const cadShoppingCentre = {
  value: 'shoppingCentre',
  label: 'Shopping Centre',
  plans: [
    {
      value: 'shoppingCentreEnterprise',
      label: 'Shopping Centre (Enterprise)',
      itemPrices: [
        {
          value: 'monthly',
          label: 'Monthly',
          plan: {
            chargebeePlanId: 'enterprise-v2-mall-monthly-cad',
            currencyCode: 'CAD'
          }
        },
        {
          value: 'annual',
          label: 'Annual',
          plan: {
            chargebeePlanId: 'enterprise-v2-mall-annual-cad',
            currencyCode: 'CAD'
          }
        }
      ]
    }
  ]
};

const cadShoppingCentreTenant = {
  value: 'shoppingCentreTenant',
  label: 'Shopping Centre Tenant',
  plans: [
    {
      value: 'shoppingCentreTenantEnterprise',
      label: 'Shopping Centre Tenant (Enterprise)',
      plan: {
        chargebeePlanId: 'enterprise-tenant-mall-monthly-cad',
        currencyCode: 'CAD',
        defaultPrice: 0
      }
    }
  ]
};

export {
  cadShoppingCentre,
  cadShoppingCentreTenant
};
