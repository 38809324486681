import { types } from './types';

export const details = id => ({
  type: types.PROPERTY_DETAILS_REQUEST,
  id,
});

export const list = () => ({
  type: types.PROPERTY_LIST_REQUEST,
});

export const invalidate = () => ({
  type: types.PROPERTY_INVALIDATE
});
