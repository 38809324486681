const usdShoppingCentre = {
  value: 'shoppingCentre',
  label: 'Shopping Centre',
  plans: [
    {
      value: 'shoppingCentreEnterprise',
      label: 'Shopping Centre',
      itemPrices: [
        {
          value: 'monthly',
          label: 'Monthly',
          plan: {
            chargebeePlanId: 'enterprise-v2-mall-monthly-usd',
            currencyCode: 'USD'
          }
        },
        {
          value: 'annual',
          label: 'Annually',
          plan: {
            chargebeePlanId: 'enterprise-v2-mall-annual-usd',
            currencyCode: 'USD'
          }
        }
      ]
    }
  ]
};

const usdShoppingCentreTenant = {
  value: 'shoppingCentreTenant',
  label: 'Shopping Centre Tenant',
  plans: [
    {
      value: 'shoppingCentreTenantEnterprise',
      label: 'Shopping Centre Tenant (Enterprise)',
      plan: {
        chargebeePlanId: 'enterprise-tenant-mall-monthly-usd',
        currencyCode: 'USD',
        defaultPrice: 0
      }
    }
  ]
};

export {
  usdShoppingCentre,
  usdShoppingCentreTenant
};
