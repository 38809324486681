import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import Dimmer from 'web-react-ui/src/components/modules/Dimmer';
import Loader from 'web-react-ui/src/components/elements/Loader';

import auth from 'shared/src/modules/auth';

const InitialStateLoading = ({ children }) => children;

class InitialState extends Component {
  static propTypes = {
    refresh: PropTypes.func,
    isPending: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    // In constructor so it starts loading before render
    props.refresh();
  }

  render() {
    const { isPending, children } = this.props;

    const LoadingContents = children.find(c => c.type === InitialStateLoading);
    const LoadedContents = children.find(c => c.type !== InitialStateLoading);

    return (
      !isPending
        ? LoadedContents
        : (
          <Dimmer.Dimmable as={'div'} dimmed style={{ height: '100vh' }}>
            {LoadingContents}
            <Dimmer simple inverted />
            <Loader active />
          </Dimmer.Dimmable>
        )
    );
  }
}

InitialState.Loading = InitialStateLoading;

const mapStateToProps = state => ({
  isPending: state.auth.isPending
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ ...auth.actions }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(InitialState);
