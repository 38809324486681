/* eslint-disable prefer-const */
import React from 'react';
import CheckboxField from 'web-react-ui/src/reactFinalForm/fields/CheckboxField';

import { required } from 'web-react-ui/src/reactFinalForm/validators';
import {
  Text,
  FormLabel,
  VStack,
  Button,
  Divider,
  Alert,
  AlertDescription,
} from '@chakra-ui/react';
import {
  useField,
  useForm
} from 'react-final-form';

import { PROVINCES } from 'shared/src/constants/countries';
import { useI18Next } from 'shared/src/components/contexts/I18NextContext';
import RadioButtonGroupField from 'web-react-ui/src/chakra/forms/RadioButtonGroup/RadioButtonGroupField';
import { Header } from 'web-react-ui/src/chakra/List';

import View from 'web-react-ui/src/components/layout/View';
import { WizardStep } from 'web-react-ui/src/components/wizard/WizardManager';
import { TextField } from 'web-react-ui/src/reactFinalForm/fields';
import SimpleSelectField from 'web-react-ui/src/reactFinalForm/fields/SimpleSelectField';
import SimpleForm from 'web-react-ui/src/reactFinalForm/SimpleForm';
import planOptionsModel from '../businessPlans/businessPlans';
import HubspotIdField from './HubspotIdField';
import PriceField from './PriceField';

const ChoosePlanStep = ({ step, manager }) => {
  const { strings } = useI18Next();

  return (
    <View>
      <SimpleForm initialValues={step.data} onSubmit={data => step.stepComplete(data)} debug>
        <View.Section narrow>
          <ChoosePlanForm />
        </View.Section>
        <View.Section className="flex" narrow>
          <Button className="mra" onClick={() => manager.showPreviousStep()}>
            {strings('ui.component.wizard.previous')}
          </Button>
          <Button
            type="submit"
            colorScheme="blue"
            // disabled={!canContinue}
            className="mla"
          >
            {strings('ui.label.next')}
          </Button>
        </View.Section>
      </SimpleForm>
    </View>
  );
};

const getNewPrice = (autoPrice, formValues) => {
  const provinceCode = PROVINCES.find(p => p.text === formValues.address.state)?.id;
  return autoPrice({ provinceCode });
};

const ChoosePlanForm = () => {
  const country = useField('address.country').input.value;
  const countryCode = country === 'United States' ? 'US' : 'CA';

  const form = useForm();
  const customerType = useField('customerType').input.value;
  const planItem = useField('planItem').input.value;
  const planItemPrice = useField('planItemPrice').input.value;

  const countryOption = planOptionsModel.countries.find(i => i.value === countryCode);
  const customerChoices = [
    { text: '-- Select Customer --', disabled: true, value: '' },
    ...countryOption?.customers?.map(customer => ({ text: customer.label, value: customer.value }))
  ];

  const customerOption = countryOption?.customers?.find(i => i.value === customerType);
  if (!customerOption) form.change('customerType');
  const planOption = customerOption?.plans?.find(i => i.value === planItem);
  if (!planOption) form.change('planItem');
  const itemPriceOption = planOption?.itemPrices?.find(i => i.value === planItemPrice);
  if (!itemPriceOption) form.change('planItemPrice');

  const selectedPlan = customerOption?.plan || planOption?.plan || itemPriceOption?.plan;

  if (selectedPlan) {
    // Uniquely identify the path taken to get here
    // eslint-disable-next-line max-len
    selectedPlan.uniqueId = `${countryOption?.value}_${customerOption?.value}_${planOption?.value}_${itemPriceOption?.value}`;
  }

  // Add selected plan to form's data
  // BUT do not reset it if it's the same plan that's already there
  // because we store the options on it as well (eg: affiliate code)
  const existingPaymentPlan = form.getState().values.paymentPlan;
  if (existingPaymentPlan?.uniqueId !== selectedPlan?.uniqueId) {
    form.change('paymentPlan', selectedPlan);

    if (selectedPlan?.autoPrice) {
      const formValues = form.getState().values;
      form.change(
        'paymentPlan.customPrice',
        getNewPrice(selectedPlan.autoPrice, formValues)
      );
    }
  }

  return (
    <VStack align="stretch" gap={4} marginBottom={4}>
      <Header>
        Plan & Services
      </Header>

      <FormLabel>
        Customer Type
        <SimpleSelectField
          wrapLabel
          name="customerType"
          options={customerChoices}
          placeholder="Select Customer Type"
          validate={required}
        />
      </FormLabel>


      {customerOption?.plans && (
        <div>
          <FormLabel>
            Select Plan
          </FormLabel>
          <RadioButtonGroupField
            name="planItem"
            options={customerOption?.plans}
            validate={required}
          />
        </div>
      )}

      {planOption?.itemPrices && (
        <div>
          <FormLabel>
            Billing Frequency
          </FormLabel>
          <RadioButtonGroupField
            name="planItemPrice"
            options={planOption?.itemPrices}
            validate={required}
          />
        </div>
      )}

      {getBonusComponent(selectedPlan)}
    </VStack>
  );
};


const getBonusComponent = (plan) => {
  if (!plan) return null;
  if (/.*_localBusiness_enterprise_.*/.test(plan.uniqueId)) {
    return (
      <>
        <Divider />
        <PriceField name="paymentPlan.customPrice" label="Plan Price" validate={required} />
        <TextField
          name="paymentPlan.affiliateCode"
          label="Affiliate Code"
          optional
          info={`Add the appropriate affiliate code here to record this sale for remittances.`}
        />
        <HubspotIdField />
      </>
    );
  }

  if (/.*_localBusiness_.*/.test(plan.uniqueId)) {
    return (
      <>
        <Divider />
        <TextField
          name="paymentPlan.affiliateCode"
          label="Affiliate Code"
          optional
          info={`Add the appropriate affiliate code here to record this sale for remittances.`}
        />
        <HubspotIdField />
      </>
    );
  }

  if (/.*cdap-social-ai.*/.test(plan.uniqueId)) {
    return (
      <>
        <HubspotIdField />
      </>
    );
  }

  if (/.*_shoppingCentre_.*/.test(plan.uniqueId) || /.*_biaTourism_.*/.test(plan.uniqueId)) {
    return (
      <>
        <PriceField name="paymentPlan.customPrice" label="Plan Price" validate={required} />
        <OnboardingFeeFields />
        <Divider />
        <TextField
          name="paymentPlan.affiliateCode"
          label="Affiliate Code"
          optional
          info={`Add the appropriate affiliate code here to record this sale for remittances.
            If no code is added, the default "npm" will be used.`}
        />
        <HubspotIdField />
      </>
    );
  }

  if (/.*_shoppingCentreTenant_.*/.test(plan.uniqueId) || /.*biaTourismCentreTenant.*/.test(plan.uniqueId)) {
    return (
      <>
        <Alert status="warning">
          <AlertDescription>
            You are creating a new business for this tenant.
            A new business should be created only if an appropriate business does not already exist.
          </AlertDescription>
        </Alert>
        <CheckboxField validate={required} name="paymentPlan.createNewBusiness">
          I certify I have checked and there is no appropriate existing business for this tenant
        </CheckboxField>
        <HubspotIdField />
      </>
    );
  }

  return null;
};

const OnboardingFeeFields = () => {
  const includeOnboardingFee = useField('paymentPlan.includeOnboardingFee').input.value;
  return (
    <>
      <CheckboxField name="paymentPlan.includeOnboardingFee">
        This sale includes an onboarding fee
      </CheckboxField>
      {includeOnboardingFee && (
        <PriceField
          name="paymentPlan.onboardingFee"
          label="Onboarding Fee"
          validate={required}
        />
      )}
      <HubspotIdField />
    </>
  );
};

export default () => new WizardStep({
  key: 'selectPlan',
  getTitle: data => data.business.name,
  render: ChoosePlanStep,
  onHide: (data) => {
    // Default business auto-tag to plan affiliate code
    if (!data.business.attributionCode && data.paymentPlan?.affiliateCode) {
      // eslint-disable-next-line no-param-reassign
      data.business.attributionCode = data.paymentPlan.affiliateCode;
    }
    return data;
  }
});
