/* eslint class-methods-use-this: 0 */
/* eslint-env browser */
import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom';
import Shell from 'web-react-ui/src/chakra/shell-admin/Shell';
import AuthExpiry from 'web-react-ui/src/components/auth/AuthExpiry';
import AuthReceiver from 'web-react-ui/src/components/auth/AuthReceiver';
import { ToastContainer } from 'web-react-ui/src/components/toasts';

import gitInfo from './.git-info.json';

import './App.css';

import InitialState from './components/InitialState';
import LoginView from './components/login/LoginView';
import PrivateRoute from './components/PrivateRoute';
import Profile from './components/Profile';
import PropertyCreateContainer from './components/property/PropertyCreateContainer';
import SiteFooter from './components/siteFooter/SiteFooter';
import Sitenav from './components/sitenav/Sitenav';
import TrackPageView from './components/TrackPageview';
import AdminRoutes from './routes/AdminRoutes';

function App() {
  // eslint-disable-next-line no-console
  console.info(`${gitInfo.name} v${gitInfo.version} (build ${gitInfo.hash})`);
  return (
    <AuthReceiver sitePermission="*user.admin.access">
      <ToastContainer />
      <InitialState>
        <InitialState.Loading>
          Please wait...
        </InitialState.Loading>
        <Router>
          <Shell>
            <AuthExpiry />
            <TrackPageView gtag={window.gtag} gaKey={process.env.REACT_APP_GA_KEY} />
            <Shell.Header>
              <Switch>
                <Route path="/login" />
                <Route path="/" component={Sitenav} />
              </Switch>
            </Shell.Header>
            <Shell.Footer>
              <Switch>
                <Route path="/login" />
                <Route path="/" component={SiteFooter} />
              </Switch>
            </Shell.Footer>
            <Switch>
              <Route path="/login" component={LoginView} />
              <PrivateRoute path="/properties/new" component={PropertyCreateContainer} />
              <Route component={AdminRoutes} />
              <PrivateRoute path="/profile" component={Profile} />
            </Switch>
          </Shell>
        </Router>
      </InitialState>
    </AuthReceiver>
  );
}

export default App;
