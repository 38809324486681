import React, { useEffect, useCallback } from 'react';
import { Box, HStack, Flex, Text, Popover, PopoverContent, PopoverTrigger, Stack } from '@chakra-ui/react';
import { QuestionIcon } from '@chakra-ui/icons';

import { useI18Next } from 'shared/src/components/contexts/I18NextContext';

import RoleSelectField from '../../reactFinalForm/fields/RoleSelectField';
import TextField from '../../reactFinalForm/fields/TextField';
import FormSubmit from '../../reactFinalForm/FormSubmit';
import SimpleForm from '../../reactFinalForm/SimpleForm';
import { required } from '../../reactFinalForm/validators';
import { Role } from '../../types/User.interface';

const InviteUserForm = ({
  onSubmit,
  type,
  roleFilter,
  dashboard,
  isInviting,
}: {
  onSubmit: ({ email, roleId, name }: { email: string, roleId: string, name: string }) => void,
  type: string,
  roleFilter?: (option: Role) => boolean,
  dashboard: boolean,
  isInviting: boolean,
}): JSX.Element => {
  const { strings } = useI18Next();

  let isUnmounted = false;
  useEffect(() => {
    return () => {
      isUnmounted = true;
    };
  }, []);

  const submitCallback = useCallback(async (values, form) => {
    const result = await onSubmit(values);

    // If the component unmounts then no need to reset the form
    if (isUnmounted) return false;

    setTimeout(() => {
      form.batch(() => {
        form.change('name', '');
        form.change('email', '');
        form.resetFieldState('name');
        form.resetFieldState('email');
      });
    }); // Cannot reset form during "submit"

    return result;
  }, [isUnmounted]);

  const RoleLabel = () => (
    <Flex justify='space-between' align='center' mb={2}>
      <Text fontWeight={600}>{strings('ui.component.inviteUserForm.role')}</Text>
      {dashboard && (
        <Popover
          trigger='hover'
          placement='top'
        >
          <PopoverTrigger>
            <QuestionIcon />
          </PopoverTrigger>
          <PopoverContent>
            <Stack direction='column' p={3}>
              <Text>{strings('ui.component.inviteUserForm.support')}</Text>
              <Text>{strings('ui.component.inviteUserForm.marketing')}</Text>
              <Text>{strings('ui.component.inviteUserForm.manager')}</Text>
            </Stack>
          </PopoverContent>
        </Popover>
      )}
    </Flex>
  );

  return (
    <SimpleForm onSubmit={submitCallback} showSubmitError>
      <Stack direction={{ base: 'column', md: 'row' }} w='100%' spacing={3} align='flex-start'>
        <Box flexGrow={1} minW='250px'>
          <Text fontWeight={600} mb={2}>
            {strings('ui.component.inviteUserForm.field.label.email')}
          </Text>
          <TextField
            name='email'
            validate={required}
            wrapLabel
            chakra
          />
        </Box>
        <Box flexGrow={1} minW='250px'>
          <Text fontWeight={600} mb={2}>
            {strings('ui.component.inviteUserForm.field.label.name')}
          </Text>
          <TextField name='name' wrapLabel validate={required} chakra />
        </Box>
        <Flex>
          <Box mr={3}>
            <RoleLabel />
            <RoleSelectField
              wrapLabel
              type={type}
              name='roleId'
              autoSelectFirst
              validate={required}
              filter={roleFilter}
              chakra
            />
          </Box>
          <Box alignSelf='flex-end'>
            <FormSubmit
              label={strings('ui.component.inviteUserForm.field.label.invite')}
              isLoading={isInviting}
            />
          </Box>
        </Flex>
      </Stack>
    </SimpleForm>
  );
};

export default React.memo(InviteUserForm);
