import PropTypes from "prop-types";
import React from 'react';
import { Link } from "react-router-dom";
import { Box, Flex, Text, useTheme } from "@chakra-ui/react";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import ProfileImage from "web-react-ui/src/components/image/ProfileImage";
import WaitFor from 'web-react-ui/src/data/WaitFor';
import ErrorMessage from 'web-react-ui/src/components/entities/ErrorMessage';

const PropertyListItem = (props) => {
  const theme = useTheme();
  const { property, baseUrl } = props;
  const { name, status, profileImage, profileImageUrl } = property || {};

  return (
    <Flex
      as={Link}
      to={`${baseUrl}/${property.id}`}
      borderTop={`solid 1px ${theme.colors.gray[300]}`}
      py={4}
      justify="space-between"
      align="center"
      sx={{
        "&:hover": {
          backgroundColor: theme.colors.gray[50],
          "& .chakra-icon": {
            color: theme.colors.gray[800]
          }
        }
      }}
    >
      <Flex align="center">
        <Box mr={4}>
          <ProfileImage
            imageId={profileImage}
            imageUrl={profileImageUrl}
            name={name}
            status={status}
          />
        </Box>
        <Text
          fontWeight={theme.fontWeights.medium}
          fontSize={theme.fontSizes.lg}
          color={theme.colors.black}
        >
          {name}
        </Text>
      </Flex>
      <ArrowForwardIcon w={5} color={theme.colors.gray[500]} ml="auto" />
    </Flex>
  );
};

PropertyListItem.propTypes = {
  property: PropTypes.object,
  baseUrl: PropTypes.string
};

const PropertySelector = (props) => {
  const { properties, error } = props;
  const theme = useTheme();
  return (
    <div>
      {error && (<ErrorMessage error={error} />)}
      <WaitFor waitFor={(properties?.items) || error}>
        <Box mt={4}>
          {properties?.items?.length ? properties.items.map(p => (
            <PropertyListItem baseUrl={props.baseUrl} property={p} key={p.id} />
          )) : (
            <Flex
              justify="center"
              align="center"
              py={4}
              borderTop={`solid 1px ${theme.colors.gray[300]}`}
              borderBottom={`solid 1px ${theme.colors.gray[300]}`}
            >
              <Text
                fontWeight={theme.fontWeights.medium}
                fontSize={theme.fontSizes.lg}
                color="black"
              >
                No Properties Found
              </Text>
            </Flex>
          )}
        </Box>
      </WaitFor>
    </div>
  );
};

PropertySelector.propTypes = {
  properties: PropTypes.object,
  baseUrl: PropTypes.string,
  error: PropTypes.object
};

export default PropertySelector;
