import {
  Button,
  Flex,
  Heading,
  Spacer,
} from '@chakra-ui/react';
import React from 'react';
import useRequest from 'shared/src/hooks/useRequest';
import TextField from 'web-react-ui/src/reactFinalForm/fields/TextField';
import SimpleForm from 'web-react-ui/src/reactFinalForm/SimpleForm';
import Business from 'web-react-ui/src/types/Business.interface';
import client from '../../../../services/client';

function ProfileSettings({ business, profile }: { business: Business, profile: Object }) {
  const updateRequest = useRequest(
    async (profileUpdates: { timezone }) => {
      return client
        .businesses.for(business.id)
        .social.updateProfile({
          timezone: profileUpdates.timezone,
        })
        .then(() => {
          // discard response instead of sending it back to the form
        });
    },
  );
  return (
    <SimpleForm initialValues={profile} onSubmit={updateRequest.run}>
      <TextField name="timezone" label="Time Zone" />
      <Flex>
        <Spacer />
        <Button type="submit" colorScheme="blue">Save</Button>
      </Flex>
    </SimpleForm>
  );
}

export default ProfileSettings;
