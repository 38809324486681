import React, { useState } from 'react';
import {
  RootStateOrAny,
  useSelector,
} from 'react-redux';
import {
  useHistory,
  useParams,
  useLocation,
} from 'react-router-dom';
import { useToast } from '@chakra-ui/react';
import authModule from 'shared/src/modules/auth';
import useRequest from 'shared/src/hooks/useRequest';
import {
  saveUserName,
  saveReferralContext,
  saveRoleChanges,
} from 'shared/src/helpers/userUtils';
import usePermissions from 'shared/src/hooks/usePermissions';
import WaitFor from 'web-react-ui/src/data/WaitFor';
import EditUserView from 'web-react-ui/src/chakra/users/EditUserView';
import { Role } from 'web-react-ui/src/types/User.interface';
import client from '../../../../services/client';
import { defaultProperties } from '../ReferralContext';

const EditUserViewModel = (): JSX.Element => {
  const toast = useToast();
  const { userId } = useParams<Record<string, string>>();
  const { pathname, state: locationState }: { pathname: string, state: { query: string } } = useLocation();
  const currentUser = useSelector((state: RootStateOrAny) => authModule.selectors.getUser(state));
  const defaultPropertyId: string = defaultProperties[process.env.REACT_APP_ENV as string];
  const history = useHistory();
  const [operationError, setOperationError] = useState<string>('');
  const [refetchId, setRefetchId] = useState(0);

  const canEditReferral = usePermissions({ propertyId: defaultPropertyId }, ['property.referralContext.update']);
  const canEditUser = usePermissions({ userId }, ['user.update']);
  const canUpdateRoles = usePermissions({ userId }, ['promote', 'demote']);

  const { result: user, loading: isLoadingUser } = useRequest(
    () => client.users.for(userId).details(),
    { userId },
  );

  const { result: referralContext, loading: isLoadingReferralContext } = useRequest(
    () => {
      if (canEditReferral) {
        return client.properties.for(defaultPropertyId).users.for(userId).getReferralContext({ dynamic: true });
      }
      return false;
    },
    { userId, canEditReferral },
  );

  const referralContextString = JSON.stringify(referralContext?.context || {});

  const { result: platformRolesResult, loading: isLoadingRoles } = useRequest(
    () => client.users.for(userId).getRoles(),
    { userId, refetchId },
  );

  const platformRoles = platformRolesResult?.items.map((item: Role) => item.id) || [];

  const { result: roles } = useRequest(() => client.roles.list({ type: 'platform' }), {});

  const sortedRoles = roles?.items.sort((x: any, y: any) => {
    /* eslint-disable-next-line no-nested-ternary */
    return x.id === 'platform-basic' ? -1 : (y.id === 'platform-basic' ? 1 : 0);
  }) || [];

  const save = (values: Record<string, any>) => {
    const { name, roles: newRoles = [], context } = values;
    return Promise.all([
      (canUpdateRoles && Promise.resolve(saveRoleChanges(user, {}, platformRoles, newRoles, client))),
      (canEditUser && Promise.resolve(saveUserName(user, name, client))),
      (canEditReferral
        && Promise.resolve(saveReferralContext(user, defaultPropertyId, referralContext, context, client))),
    ]);
  };

  const saveRequest = useRequest(save);

  const handleSave = async (values: Record<string, any>) => {
    if (!user) return false;
    const { name } = values;
    if (user.name && !name) {
      setOperationError('Name cannot be empty.');
      return false;
    }
    const toastProps = { duration: 5000, isClosable: true, position: 'top-right' };
    await saveRequest.run(values);
    if (saveRequest.settled && !saveRequest.error) {
      // @ts-ignore
      toast({ ...toastProps, title: 'User Profile Saved!', status: 'success' });
      setRefetchId(refetchId + 1);
    } else if (saveRequest.error) {
      // @ts-ignore
      toast({ ...toastProps, title: err, status: 'error' });
    }

    return true;
  };

  const handleCancel = () => history.push({
    pathname: pathname.replace('/edit', ''),
    state: locationState,
  });

  const deleteRequest = useRequest(() => client.users.for(user.id).remove({ recursive: true }));

  const handleDelete = async () => {
    if (!user) return null;
    /* eslint-disable-next-line no-restricted-globals */
    const shouldDelete = confirm(
      /* eslint-disable-next-line max-len */
      `Are you sure you want to delete ${user.email}? Deleting this user will remove all property, business, and location roles.`,
    );
    if (!shouldDelete) return null;

    await deleteRequest.run();
    if (deleteRequest.settled && !deleteRequest.error) {
      history.push('/users');
    }

    return true;
  };

  const key = [...platformRoles, referralContext, user?.name].join(':');
  const isLoading = isLoadingRoles || isLoadingUser || isLoadingReferralContext;

  return (
    <WaitFor waitFor={currentUser && user}>
      {currentUser && user && roles && (
        <EditUserView
          key={key}
          isSaving={saveRequest.loading}
          isLoading={isLoading}
          isDeleting={deleteRequest.loading}
          user={user}
          canEditUser={canEditUser}
          handleSave={handleSave}
          handleCancel={handleCancel}
          handleDelete={handleDelete}
          roles={sortedRoles}
          roleTypes={['Platform Roles']}
          canUpdateRoles={canUpdateRoles}
          initialRoles={platformRoles}
          initialContext={referralContextString}
          canEditContext={canEditReferral}
          operationError={operationError || deleteRequest.error}
          backUrl="/users"
          context="platform"
        />
      )}
    </WaitFor>
  );
};

export default EditUserViewModel;
