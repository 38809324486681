import {
  Flex,
  Heading,
  Skeleton,
  SkeletonText,
} from '@chakra-ui/react';
import React from 'react';
import { useParams } from 'react-router-dom';
import useRequest from 'shared/src/hooks/useRequest';
import PageBackButton from 'web-react-ui/src/chakra/page/PageBackButton';
import client from '../../../../services/client';


const PostDetails = () => {
  const params = useParams<{ postId: string, businessId: string }>();

  const postRequest = useRequest(
    ({ postId, businessId }) => {
      return client
        .businesses.for(businessId)
        .social.posts.for(postId)
        .details();
    },
    { postId: params.postId, businessId: params.businessId },
  );

  return (
    <Flex direction="column">
      <PageBackButton to="../">Back</PageBackButton>
      <Heading>Post Details</Heading>
      <SkeletonText isLoaded={postRequest.settled}>
        <pre style={{ whiteSpace: 'pre-wrap' }}>{JSON.stringify(postRequest.result, null, 2)}</pre>
      </SkeletonText>
    </Flex>
  );
};

export default PostDetails;
