import React from 'react';
import { Box, Flex, Image } from "@chakra-ui/react";

import LoginContainer from './LoginContainer';
import LMGlogo from "../../assets/LMGlogo.png";

const LoginView = () => (
  <Flex align="stretch">
    <Flex
      w={{ base: '100%', md: '40%' }}
      minW={{ base: '100%', md: 480 }}
      direction="column"
      justify="center"
      align="center"
    >
      <Flex maxW={{ base: 300, sm: 400 }} mb={8} width="100%" justify="end">
        <Image src={LMGlogo} alt="logo" ml="auto" width="80px" />
      </Flex>
      <LoginContainer />
    </Flex>
    <Box background='gray.50' minH="100vh" width={{ base: 0, md: '60%' }} />
  </Flex>
);

export default LoginView;
