/* eslint-disable no-restricted-globals */
/* eslint-disable no-alert */
import React from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { RootStateOrAny, useSelector } from 'react-redux';

import authModule from 'shared/src/modules/auth';
import useRequest from 'shared/src/hooks/useRequest';

import UserProfileView from 'web-react-ui/src/chakra/users/UserProfileView';

import client from '../../../services/client';

const UserProfile = (): JSX.Element => {
  const currentUser = useSelector((state: RootStateOrAny) => authModule.selectors.getUser(state));
  const history = useHistory();
  const { userId } = useParams<Record<string, string>>();
  const { pathname, state }: { pathname: string, state: { query: string } } = useLocation();

  const { result: roles } = useRequest(() => client.roles.list(), {});
  const { result: user } = useRequest(() => client.users.for(userId).details(), { userId });
  const { result: platformRoles } = useRequest(() => client.users.for(userId).getRoles(), { userId });
  const { result: properties } = useRequest(() => client.users.for(userId).properties(), { userId });

  const deleteRequest = useRequest(() => client.users.for(user.id).delete());
  const removeUser = async () => {
    if (!user) return null;
    const shouldDelete = confirm(
      /* eslint-disable-next-line max-len */
      `Are you sure you want to delete ${user.email}? Deleting this user will remove all property, business, and location roles.`
    );
    if (!shouldDelete) return null;

    await deleteRequest.run();
    if (!deleteRequest.error) {
      history.push(`/users${state?.query}`);
    }

    return true;
  };

  const onEdit = () => history.push({
    pathname: `${pathname}/edit`,
    state,
  });

  return (
    <UserProfileView
      user={user}
      isCurrentUser={currentUser && user?.id === currentUser.id}
      onEdit={onEdit}
      removeUser={removeUser}
      properties={properties?.items}
      platformRoles={platformRoles?.items}
      isDeleting={deleteRequest.loading}
      operationError={deleteRequest.error}
      roles={roles?.items}
      backUrl={`/users`}
    />
  );
};

export default UserProfile;
