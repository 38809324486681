import { Heading } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';
import {
  formatSocial,
  parseSocial,
  SOCIAL_PROFILE_FIELDS
} from 'shared/src/constants/socialProfiles';

import usePromise from 'shared/src/hooks/usePromise';

import BigModal from 'web-react-ui/src/BigModal/BigModal';
import Menu from 'web-react-ui/src/components/collections/Menu';
import CancelButton from 'web-react-ui/src/components/elements/CancelButton';
import ImageField from 'web-react-ui/src/components/image/ImageField';
import View from 'web-react-ui/src/components/layout/View';
import WaitFor from 'web-react-ui/src/data/WaitFor';
import { TextField } from 'web-react-ui/src/reactFinalForm/fields';
import FieldListField from 'web-react-ui/src/reactFinalForm/fields/FieldListField';
import LocaleSelector from 'web-react-ui/src/reactFinalForm/fields/localizableField/LocaleSelector';
import LocalizableSimpleForm from 'web-react-ui/src/reactFinalForm/fields/localizableField/LocalizableSimpleForm';
import LocalizableTextArea from 'web-react-ui/src/reactFinalForm/fields/localizableField/LocalizableTextArea';
import LocalizableTextField from 'web-react-ui/src/reactFinalForm/fields/localizableField/LocalizableTextField';
import FormError from 'web-react-ui/src/reactFinalForm/FormError';
import FormSubmit from 'web-react-ui/src/reactFinalForm/FormSubmit';
import {
  atLeastOneCompleteLocalization,
  forAllLocales,
  max,
  required,
  requiredArray,
  slug
} from 'web-react-ui/src/reactFinalForm/validators';
import client from '../../services/client';

import CategoriesSelectorField from '../categories/CategoriesSelectorField';
import PlatformTranslationWrapper from '../i18n/PlatformTranslationWrapper';

const EditBusinessModal = ({
  business, onSubmit, onCancel, open
}) => {
  const [, loading, businessEdit] = usePromise(
    () => client.businesses.for(business.id).editWithLocalization(),
    [business, client],
    null,
    true
  );

  return (
    <BigModal open={open}>
      <WaitFor waitFor={!loading}>
        <PlatformTranslationWrapper>
          <LocalizableSimpleForm
            onSubmit={onSubmit}
            initialValues={businessEdit}
            validateLocale={atLeastOneCompleteLocalization([
              'nameLocalized', 'shortNameLocalized', 'descriptionLocalized'
            ])}
          >
            <BigModal.Contents>
              <BigModal.Header className="p1">
                <Menu secondary>
                  <Menu.Item fitted>
                    <CancelButton type="button" onClick={onCancel} />
                  </Menu.Item>
                  <Menu.Menu position="right">
                    <Menu.Item fitted>
                      <FormSubmit label="Save" ignoreValidity />
                    </Menu.Item>
                  </Menu.Menu>
                </Menu>
              </BigModal.Header>
              <BigModal.Body>
                <View>
                  <View.Section narrow>
                    <div className="flex mb1">
                      <h2>Edit Business</h2>
                      <LocaleSelector inline className="mla" />
                    </div>
                    <FormError />
                    <LocalizableTextField
                      label="Name"
                      name="nameLocalized"
                      placeholder="Name"
                      validateLocale={forAllLocales(max(64))}
                    />

                    <LocalizableTextField
                      label="Short Name"
                      name="shortNameLocalized"
                      placeholder="Short Name"
                      validateLocale={forAllLocales(max(24))}
                    />

                    <TextField label="Slug" name="slug" placeholder="Slug" validate={slug} />
                    <TextField label="Slogan" name="slogan" placeholder="Slogan" validate={max(64)} />

                    <LocalizableTextField
                      label="Slogan"
                      name="sloganLocalized"
                      placeholder="Slogan"
                      validateLocale={forAllLocales(max(64))}
                    />
                    <LocalizableTextArea
                      label="Description"
                      name="descriptionLocalized"
                      placeholder="Description"
                      validateLocale={forAllLocales(max(254))}
                    />
                    <CategoriesSelectorField label="Categories" name="categories" validate={requiredArray} leafOnly />
                  </View.Section>
                  <View.Section narrow>
                    <ImageField name="profileImage" fileType="business-profile" label="Profile Image" />
                    <ImageField name="logoImage" fileType="business-logo-standard" label="Logo" />
                    <ImageField
                      name="heroImage"
                      fileType="business-hero-standard"
                      label="Hero Image"
                      validate={required}
                    />
                  </View.Section>
                  <View.Section narrow>
                    <h6>Contact</h6>
                    <TextField label="Homepage" name="url" type="url" validate={max(512)} parse={v => v} />
                    <TextField label="Phone" name="phone" validate={max(35)} />
                  </View.Section>
                  <View.Section narrow>
                    <Heading size="md" mb={2}>Social Media Profiles</Heading>
                    <FieldListField
                      name="socialProfiles"
                      addButtonLabel="Add"
                      fields={SOCIAL_PROFILE_FIELDS}
                      parse={parseSocial}
                      format={formatSocial}
                    />
                  </View.Section>
                </View>

              </BigModal.Body>
            </BigModal.Contents>
          </LocalizableSimpleForm>
        </PlatformTranslationWrapper>
      </WaitFor>
    </BigModal>
  );
};

EditBusinessModal.propTypes = {
  business: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  open: PropTypes.bool
};

export default EditBusinessModal;
