/* eslint-disable no-unused-vars */
import {
  COUNTRIES,
  PROVINCES,
  US_STATES
} from 'shared/src/constants/countries';

import { logError } from 'shared/src/helpers/log';
import {
  autoCrop,
  getImageFileUrlAsFile,
  getImageFileUrlDimensions
} from 'web-react-ui/src/components/imageWell/imageUtils';

import Pipeline, { Step } from 'shared/src/modules/pipeline/Pipeline';

import { strings } from '../../../i18n';

export const saveBusiness = async (data, results, config, { step, pipeline }) => {
  const { businessFields, property, user, addressFields: { billing }, paymentPlan, hubspot } = data;

  // eslint-disable-next-line no-param-reassign
  results.business = await config.client.properties.for(property.id)
    .businesses.selfSignupBusiness({
      name: businessFields.name,
      shortName: businessFields.shortName,
      token: businessFields.token,
      // If attribution code is set, don't auto-attribute from user's referral context
      skipReferralContextAttribution: !!businessFields.attributionCode
    });

  const businessPropertyRepo = config.client
    .properties.for(property.id)
    .businesses.for(results.business.id);

  await businessPropertyRepo.autoLink();


  // If we have a token, that means the Chargebee Customer was already created, so we're done.
  if (businessFields.token) return;


  // AFTER policy is created (and Customer is auto-created) we can set the initial Customer properties...
  try {
    await businessPropertyRepo.initializeCustomer({
      createdByEmail: user.email,
      company: results.business.name,
      hubspotCompanyId: hubspot?.companyId,
      hubspotDealId: hubspot?.dealId
      // email: user.email // TODO: Have a place to capture the customer's email and set it here
    });
  } catch (err) {
    // We can still checkout without this information so we'll just make a note that this failed.
    // There _is_ an edge-case where a user chooses a US plan but the address initialize fails.
    // In this case, attempting to upgrade a plan will present the user with Canadian plans.
    // BUT, our API prevents checking out with a different currency from the existing plan!

    logError(err);
  }

  // ...and initial Billing Address...
  const countryFound = COUNTRIES.find(country => country.value === billing.country);
  try {
    await businessPropertyRepo.initializeBillingAddress({
      company: results.business.name,
      line1: `${billing.streetAddress} ${billing.street}`,
      city: billing.city,
      stateCode: ((countryFound?.keyName === COUNTRIES[1].keyName) ? US_STATES : PROVINCES)
        .find(p => p.value === billing.state).id,
      zip: billing.postalcode,
      country: countryFound?.keyName // todo: ensure we use 2-letter country code
    });
  } catch (err) {
    // We can still checkout without this information so we'll just make a note that this failed...
    logError(err);
  }
};

export const updateBusinessDetails = async (data, results, config, { step, pipeline }) => {
  if (!results.business) {
    throw new Error(strings('dashboard.component.createBusinessPipeline.error.updateBusinessDetails'));
  }
  if (results.business.description) return;

  const { businessFields } = data;
  const businessRepo = config.client.businesses.for(results.business.id);

  // eslint-disable-next-line no-param-reassign
  results.business = await businessRepo.update(results.business, businessFields);
};

export const saveAddress = async (data, results, config, { step, pipeline }) => {
  // eslint-disable-next-line no-param-reassign
  results.address = await config.client.addresses.create(data.addressFields);
};

export const saveLocation = async (data, results, config, { step, pipeline }) => {
  if (!results.address) {
    throw new Error(strings('dashboard.component.createBusinessPipeline.error.saveLocation'));
  }

  const { address, business } = results;
  const { addressFields, property } = data;

  const location = {
    address,
    // Use the street, then the city, then a "full address label" as the Location name
    name: (addressFields.street || addressFields.city || address.label.substr(0, 64)),
    phone: addressFields.phone,
    url: addressFields.url || business.url // HACK: Set business url on Location until fallback bug is fixed
  };

  const businessRepo = config.client.businesses.for(business.id);
  const businessPropertyRepo = config.client.properties.for(property.id).businesses.for(business.id);

  const newLocation = await businessRepo.locations.create(location);
  await businessPropertyRepo.locations.for(newLocation.id).autoLink();

  // eslint-disable-next-line no-param-reassign
  results.location = newLocation;
};

// TODO: Move all this junk into a single helper somewhere
const autoUploadImage = async (client, imageUrl, fileType) => {
  const fileTypeInfo = await client.images.getType(fileType);
  const file = getImageFileUrlAsFile(imageUrl);
  const croppedImageUrl = await autoCrop(file, fileTypeInfo);
  const croppedFile = getImageFileUrlAsFile(croppedImageUrl);
  const { width, height } = await getImageFileUrlDimensions(croppedImageUrl);
  const imageRef = await client.images.create(
    croppedFile,
    fileType,
    croppedFile.type,
    width,
    height,
    croppedFile.size
  );
  return imageRef.id;
};

export const saveImages = async (data, results, config, { step, pipeline }) => {
  if (!results.business || !results.business.id) {
    throw new Error(strings('dashboard.component.createBusinessPipeline.error.saveImages'));
  }
  if (results.business
    && results.business.logoImage
    && results.business.profileImage
    && results.business.heroImage) {
    return;
  }
  const { client } = config;
  const { businessFields } = data;
  const businessRepo = client.businesses.for(results.business.id);

  const pending = [];
  if (!results.business.logoImage && businessFields.rawProfileImage) {
    pending.push(autoUploadImage(client, businessFields.rawProfileImage, 'business-logo-standard'));
  } else {
    pending.push(Promise.resolve(results.business.logoImage));
  }

  if (!results.business.profileImage && businessFields.rawProfileImage) {
    pending.push(autoUploadImage(client, businessFields.rawProfileImage, 'business-profile'));
  } else {
    pending.push(Promise.resolve(results.business.profileImage));
  }

  if (!results.business.heroImage && businessFields.rawHeroImage) {
    pending.push(autoUploadImage(client, businessFields.rawHeroImage, 'business-hero-standard'));
  } else {
    pending.push(Promise.resolve(results.business.heroImage));
  }

  const res = await Promise.all(pending);
  businessFields.logoImage = res[0];
  businessFields.profileImage = res[1];
  businessFields.heroImage = res[2];

  // TODO: Can JUST the images be updated in this call...?
  // eslint-disable-next-line no-param-reassign
  results.business = await businessRepo.update(results.business, businessFields);
};

export const tagBusiness = async (data, results, config) => {
  const { businessFields, property } = data;
  if (!businessFields.attributionCode) return undefined;

  try {
    return config.client
      .properties.for(property.id)
      .businesses.for(results.business.id)
      .addTag(businessFields.attributionCode.toLowerCase());
  } catch {
    // Nobody cares
    return undefined;
  }
};

export default class CreateBusinessPipeline extends Pipeline {
  constructor(client) {
    super(null, null, { client });
    this.steps = [
      new Step(
        saveBusiness,
        async (e, data, results, config, { step, pipeline }) => {
          // Business name is already taken this retries to get a unique one
          if (e.messageId === '06-67-001') {
            // TODO: Figure out better recovery here. This feels very
            // brittle & hacky...
            if (step.meta.retryCount === 0) {
              // eslint-disable-next-line no-param-reassign
              data.originalBusinessName = data.businessFields.name;
              // eslint-disable-next-line no-param-reassign,max-len
              data.businessFields.name = `${data.originalBusinessName} - ${data.addressFields.city}`;
            } else if (step.meta.retryCount === 1) {
              // eslint-disable-next-line no-param-reassign,max-len
              data.businessFields.name = `${data.originalBusinessName} - ${data.addressFields.street}, ${data.addressFields.city}`;
            } else if (step.meta.retryCount === 2) {
              // eslint-disable-next-line no-param-reassign,max-len
              data.businessFields.name = `${data.originalBusinessName} - ${data.addressFields.streetAddress} ${data.addressFields.street}, ${data.addressFields.city}`;
            } else {
              // eslint-disable-next-line no-param-reassign
              data.businessFields.name += ` ${Math.round(Math.random() * 100)}`;
            }
            return;
          }

          throw e;
        }
      ),
      new Step(updateBusinessDetails),
      new Step(saveAddress),
      new Step(saveLocation),
      new Step(saveImages),
      new Step(tagBusiness)
    ];
  }
}
