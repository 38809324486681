import ContentTypeBadge from 'business-dashboard/src/components/businesses/social/components/ContentTypeBadge';
import NetworkIcon from 'business-dashboard/src/components/businesses/social/components/NetworkIcon';
import StatusBadge from 'business-dashboard/src/components/businesses/social/components/StatusBadge';
import React from 'react';
import {
  Box,
  Flex,
  Image,
  LinkBox,
  LinkOverlay,
  Text,
} from '@chakra-ui/react';
import Post from 'business-dashboard/src/components/businesses/social/interfaces/Post.interface';
import { Link } from 'react-router-dom';
import usePagedList from 'shared/src/hooks/usePagedList';
import {
  Empty,
  TableCell,
  TableList,
  TableRow,
} from 'web-react-ui/src/chakra/TableList/TableList';
import Business from 'web-react-ui/src/types/Business.interface';
import client from '../../../../services/client';

const fetchPosts = async ({
  businessId,
}: {
  businessId: string;
}): Promise<{ items: Array<Post> } | undefined> => {
  if (!businessId) return undefined;
  return client.businesses.for(businessId).social.posts.list();
};

const postColumns = [
  {
    key: 'content',
    label: 'Content',
    cell: {
      w: '100%',
    },
    skeletonCircle: {
      boxSize: '6em',
    },
    skeletonText: {
      noOfLines: 2,
      w: '100%',
    },
  },
  {
    key: 'date',
    label: 'Scheduled Date',
    cell: {
      minW: '12rem',
    },
    skeletonText: {
      noOfLines: 1,
    },
  },
  {
    key: 'status',
    label: 'Status',
    cell: {
      minW: '8rem',
      textAlign: 'right',
    },
    skeletonText: {
      noOfLines: 1,
    },
  },
];

const SocialPostsList = ({ business }: { business: Business }) => {
  const postList = usePagedList(
    fetchPosts,
    {
      propertyId: process.env.REACT_APP_DEFAULT_PROPERTY,
      businessId: business.id,
    },
    [business],
  );

  return (
    <TableList columns={postColumns} isLoading={postList.loading}>
      <Empty isEmpty={postList.empty}>
        <Text fontSize="xl">No Posts To Display</Text>
      </Empty>
      {postList.items.map((post: Post) => (
        <PostItem key={post.id} post={post} business={business} />
      ))}
    </TableList>
  );
};

const PostItem = ({ business, post }: { business: Business; post: Post }) => {
  return (
    <LinkBox as={TableRow} class="_postItem">
      <TableCell column={postColumns[0]}>
        <Flex gap="1em" align="center">
          <Box position="relative" flexShrink={0}>
            <Box
              position="absolute"
              top="-0.5em"
              right="-0.5em"
              fontSize="16px"
              color="#4267B2"
            >
              <NetworkIcon id={post.socialNetwork} fontSize="1.5em" />
            </Box>
            <Image
              src={post.output.media?.[0]}
              boxSize="6em"
              minW="6em"
              bg="#eee"
            />
          </Box>
          <Flex direction="column">
            <LinkOverlay as={Link} to={`/businesses/${business.id}/features/post/${post.id}`}>
              <Flex gap="1em" align="center">
                <ContentTypeBadge type={post.contentType} />
                <Text fontWeight="bold">{post.campaignName}</Text>
              </Flex>
            </LinkOverlay>
            <Text noOfLines={1}>{post.output.message}</Text>
          </Flex>
        </Flex>
      </TableCell>
      <TableCell column={postColumns[1]}>
        <Text whiteSpace="nowrap">{post.scheduledDate}</Text>
      </TableCell>
      <TableCell column={postColumns[2]}>
        <StatusBadge status={post.displayState} />
      </TableCell>
    </LinkBox>
  );
};

export default SocialPostsList;
