/* eslint class-methods-use-this: 0 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import withAsyncState from 'shared/src/hoc/withAsyncState';
import View from 'web-react-ui/src/components/layout/View';

import PropertySelector from '../property/PropertySelector';
import client from '../../services/client';

class PropertySelectView extends Component {
  static propTypes = {
    properties: PropTypes.object,
    onEmptyProperties: PropTypes.func
  };

  render() {
    const { properties } = this.props;
    return (
      <View>
        <View.Section>
          <h1>Select Property</h1>
          <PropertySelector baseUrl={'/properties'} properties={properties} />
          <Link to='/properties/new' className='pull-right'>+ New Property</Link>
        </View.Section>
      </View>
    );
  }
}

class PropertySelectContainer extends Component {
  static propTypes = {
    properties: PropTypes.object
  };

  render() {
    const { properties } = this.props;

    return (
      <PropertySelectView properties={properties} />
    );
  }
}

const config = {
  load: () => client.properties.listRaw().then(client.pagination.loadRest)
};
const render = (state, props) => <PropertySelectContainer properties={state.data} {...props} />;
export default withAsyncState(config)(render);
export { PropertySelectView as Presenter };
