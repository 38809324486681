import React, { useEffect, useState } from 'react';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import { Box, Button, Flex, Heading } from '@chakra-ui/react';
import { Redirect, useHistory } from 'react-router-dom';

import ActionMenu from 'web-react-ui/src/chakra/ActionMenu';
import WaitFor from 'web-react-ui/src/data/WaitFor';
import LocationDetails from 'web-react-ui/src/chakra/LocationDetails';
import Location from 'web-react-ui/src/types/Location.interface';

import client from '../../services/client';
import confirm from '../../services/confirm';
import EditLocationModal from '../locations/EditLocationModal';

const AdminBusinessLocationDetails = ({
  locationId,
  businessId,
  updateLocationAddress,
}: {
  locationId: string,
  businessId: string,
  updateLocationAddress: (addressId?: string) => void
}): JSX.Element => {
  const [location, setLocation] = useState<Location>();
  const [editLocation, setEditLocation] = useState(false);
  const [locationEditInstance, setLocationEditInstance] = useState(null);
  const [loadingLocation, setLoadingLocation] = useState(false);
  const [isBusy, setIsBusy] = useState(false);
  const [redirect, setRedirect] = useState<string>();
  const [externalIds, setExternalIds] = useState<{ key: string, value: string }[]>([]);
  const [locationError, setLocationError] = useState<string>();
  const history = useHistory();

  const fetchLocation = () => {
    return client
      .businesses.for(businessId)
      .locations.for(locationId)
      .adminDetails();
  };

  const fetchExternalIds = () => {
    return client
      .businesses.for(businessId)
      .locations.for(locationId)
      .getExternalPlaceIds();
  };

  useEffect(() => {
    if (businessId && locationId && location) {
      fetchExternalIds().then((data: { [key: string]: any }) => {
        const dataArray = Object.entries(data || {})
          .filter(([, value]) => !!value)
          .map(item => ({ key: item[0], value: item[1] }));
        setExternalIds(dataArray);
      });
    }
  }, [businessId, locationId, location]);

  useEffect(() => {
    if (businessId && locationId) {
      fetchLocation().then((data: Location) => {
        setLocation(data);
      });
    }
  }, [businessId, locationId]);

  const handleEditLocation = () => {
    setEditLocation(true);
    setLoadingLocation(true);
    client
      .businesses.for(businessId)
      .locations.for(locationId)
      .editWithLocalization()
      .then((data: any) => { // @todo figure out better typing for this
        setLocationEditInstance(data);
        setLoadingLocation(false);
      });
  };

  const cancelEditLocation = () => {
    setEditLocation(false);
  };

  const saveExternalPlaceIds = (extIds: Array<{ key: string, value: string }>) => {
    const externalPlaceIds: { [key: string]: string } = {};
    extIds.forEach((item) => {
      if (item.key && item.value) {
        externalPlaceIds[item.key] = item.value;
      }
    });

    return client
      .businesses.for(businessId)
      .locations.for(locationId)
      .setExternalPlaceIds(externalPlaceIds);
  };

  const saveLocationData = (locationFields: Record<string, any>) => client
    .businesses.for(businessId)
    .locations.for(locationId)
    .update(location, locationFields);


  const saveLocation = (newFields: Record<string, any>) => {
    const { externalIds: extIds, ...locationFields } = newFields;
    return Promise.all([
      saveExternalPlaceIds(extIds),
      saveLocationData(locationFields),
    ]).then(([, updatedLocation]) => {
      cancelEditLocation();
      setLocation(updatedLocation);
    }).catch((err: Record<string, string>) => {
      setLocationError(err.message);
    });
  };

  const deleteLocation = () => {
    if (!location) return false;
    if (!confirm(`Are you sure you want to delete ${location.name}?`)) return false;
    setIsBusy(true);
    return client
      .businesses.for(businessId)
      .locations.for(locationId)
      .delete()
      .then(() => {
        setIsBusy(false);
        setRedirect(`/businesses/${businessId}/locations`);
      });
  };

  const actions = [
    { label: 'Edit Location', action: handleEditLocation },
    { label: 'Update Address from OSM', action: () => updateLocationAddress(location?.address?.id) }
  ];
  if (redirect) return <Redirect to={redirect} />;

  return (
    <Box maxW='7xl' mx='auto' p={5}>
      <WaitFor waitFor={location && !isBusy}>
        <Button
          colorScheme='blue'
          variant='link'
          fontSize='xl'
          leftIcon={<ChevronLeftIcon />}
          onClick={() => history.goBack()}
          my={5}
        >
          Back
        </Button>
        <Flex align='center' justify='space-between'>
          <Heading>Location Details</Heading>
          <ActionMenu actions={actions} />
        </Flex>
        <EditLocationModal
          location={locationEditInstance}
          externalIds={externalIds}
          open={editLocation}
          onCancel={cancelEditLocation}
          onSubmit={saveLocation}
          loading={loadingLocation}
          error={locationError}
        />
        <LocationDetails
          isLoading={loadingLocation}
          location={location}
          externalIds={externalIds}
          showExternalIds={true}
        />
        {process.env.REACT_APP_ENV !== 'prod' && (
          <Flex mt={8}>
            <Button colorScheme='red' onClick={deleteLocation} ml='auto'>
              Delete Location
            </Button>
          </Flex>
        )}
      </WaitFor>
    </Box >
  );
};

export default AdminBusinessLocationDetails;
