import React, {
  useCallback,
  useContext
} from 'react';
import PropTypes from 'prop-types';

import ClientContext from 'shared/src/components/contexts/ClientContext';

import AsyncSelectField from './AsyncSelectField';

function RoleSelectField({ type, ...rest }) {
  const client = useContext(ClientContext);
  const fetchRoles = useCallback(() => client
    .roles.list({ type })
    .then(result => result.items
      .map(item => ({
        value: item.id,
        key: item.id,
        text: item.name,
        description: item.desc,
        sid: item.sid // not used by Select but needed for filtering
      }))), [type, client]);

  return <AsyncSelectField fetchOptions={fetchRoles} {...rest}/>;
}

RoleSelectField.propTypes = {
  type: PropTypes.string
};

export default RoleSelectField;
