import {
  Badge,
  Divider,
  Heading,
  Skeleton,
} from '@chakra-ui/react';
import React, { useMemo } from 'react';
import useRequest from 'shared/src/hooks/useRequest';
import PageHeader from 'web-react-ui/src/chakra/page/PageHeader';
import PageLayout from 'web-react-ui/src/chakra/page/PageLayout';
import ActionMenu from 'web-react-ui/src/components/actionMenu/ActionMenu';
import Business from 'web-react-ui/src/types/Business.interface';
import client from '../../../../services/client';
import confirm from '../../../../services/confirm';
import BrandSettings from './BrandSettings';
import ProfileSettings from './ProfileSettings';
import SocialPostsList from './SocialPostsList';
import SocialCampaignsList from './SocialCampaignsList';

const disableProfile = async (business: Business) => {
  if (!confirm(
    [
      `Disable Social AI for ${business.name}?`,
      '\n\n',
      'All links to social media accounts will be removed and all stats will stop syncing. ',
      'Any scheduled posts will not be posted.',
      '\n\n',
      'This cannot be undone.',
    ].join(''),
  )) {
    return null;
  }

  return client
    .properties.for(process.env.REACT_APP_DEFAULT_PROPERTY)
    .businesses.for(business.id)
    .social.updateProfile({ enabled: false });
};

const fetchProfile = async (
  { businessId }:
    { businessId: string },
): Promise<any> => {
  if (!businessId) return undefined;
  return client
    .businesses.for(businessId)
    .social.getProfile(
      {},
      {
        fields: [
          'name',
          'socialAccounts.type',
          'socialAccounts.linked',
          'timezone',
          'enabled',
          'created',
          'isLinked',
          'health',
          'branding'
        ],
      },
    )
    .catch((err) => {
      if (err.status === 404) return {};
      throw err;
    });
};

const ProfileStatusBadge = ({ profile }: { profile?: { enabled?: boolean } }) => {
  const props = {
    p: '0.75em',
    children: '',
    colorScheme: 'gray',
  };

  if (profile?.enabled === true) {
    props.children = 'Enabled';
    props.colorScheme = 'green';
    props.children = 'Enabled';
  } else if (profile?.enabled === false) {
    props.children = 'Disabled';
    props.colorScheme = 'red';
  } else {
    props.children = 'Not Configured';
  }
  return <Badge {...props} />;
};

const AdminSocialSection = ({ business }: { business: Business }) => {
  const disableRequest = useRequest(disableProfile);
  const profileRequest = useRequest(
    fetchProfile,
    { businessId: business.id, counter: disableRequest.counter },
  );

  const actions = useMemo(
    () => ([
      {
        label: 'Disable Profile',
        negative: true,
        action: async () => {
          disableRequest.run(business);
        },
        extraProps: {
          isLoading: disableRequest.loading,
        },
        hidden: !profileRequest.result?.enabled,
      },
    ]),
    [business, disableRequest, profileRequest.result],
  );

  return (
    <PageLayout>
      <PageHeader controls={<ActionMenu actions={actions} suppressPrimary />}>
        Social AI
        <Skeleton display="inline-block" isLoaded={profileRequest.settled} mx="1em">
          <ProfileStatusBadge profile={profileRequest.result} />
        </Skeleton>
      </PageHeader>
      {
        profileRequest.result?.enabled !== undefined
          ? (
            <>
              <Heading size="lg">Settings</Heading>
              <ProfileSettings business={business} profile={profileRequest.result} />
              <Heading size="lg">Brand</Heading>
              <BrandSettings business={business} profile={profileRequest.result} />
              <Divider />
              <Heading size="lg">Campaigns</Heading>
              <SocialCampaignsList business={business} />
              <Divider />
              <Heading size="lg">Posts</Heading>
              <SocialPostsList business={business} />
            </>
          )
          : null
      }
    </PageLayout>
  );
};

export default AdminSocialSection;
