import { ExternalLinkIcon } from '@chakra-ui/icons';
import {
  Box,
  Code,
  Flex,
  Skeleton,
  Table,
  Tbody,
  Td,
  Text,
  Tr,
} from '@chakra-ui/react';
import React from 'react';
import SectionHeader from 'shared/src/components/SectionHeader';
import StatusBadge from 'shared/src/components/StatusBadge';
import Content from 'shared/src/details/ContentSection';
import Preview from 'shared/src/details/PreviewSection';
import Post from 'shared/src/interfaces/Post.interface';
import PageHeader from 'web-react-ui/src/chakra/page/PageHeader';
import ActionMenu from 'web-react-ui/src/components/actionMenu/ActionMenu';

const SocialPostPreview = ({
  post,
  actions,
}: {
  post: Post & { pageTitle: string };
  actions: any;
}) => {
  return (
    <Box
      as="div"
      w="auto"
      mx={{ base: '0.5rem', md: '4rem' }}
      mb="4rem"
      className="_mainContainer"
      color="gray.800"
    >
      <Box w="100%">
        <PageHeader controls={<ActionMenu actions={actions} />}>
          {post.pageTitle}
        </PageHeader>
      </Box>
      <Flex
        wrap="wrap"
        direction={{ base: 'column', md: 'row' }}
        gap="3em"
        minW="1/2"
        className="_postDetailContainer"
        justifyContent="center"
      >
        <Flex direction="column" gap="1.5em" flex="1" alignItems="start">
          <Details post={post} />
          <Box>
            <SectionHeader level={3}>Content</SectionHeader>
            <Stats post={post} />
          </Box>
          <Content post={post} />
        </Flex>
        <Preview post={post} />
      </Flex>
    </Box>
  );
};

const Details = ({ post }: { post: Post }) => {
  let formattedDate;

  if (post?.scheduledDate) {
    const date = new Date(post.scheduledDate);
    formattedDate = new Intl.DateTimeFormat('en-US', {
      weekday: 'short',
      day: '2-digit',
      year: 'numeric',
      month: 'short',
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
    }).format(date);
  }

  return (
    <Table variant="unstyled" w="auto" data-text="details component">
      <Tbody fontWeight="bold" fontSize="1.3rem" color="gray.600">
        <Tr>
          <Td pt={0} pl={0}>
            Schedule Date
          </Td>
          <Td pt={0}>
            <Text fontSize="1.2rem" fontWeight="normal">
              {formattedDate}
            </Text>
          </Td>
        </Tr>
        <Tr>
          <Td pt={0} pl={0}>
            Status
          </Td>
          <Td pt={0}>
            <Flex gap="1em" align="center">
              <StatusBadge status={post?.displayState} />
              {post?.socialNetworkUrl ? (
                <a
                  href={post.socialNetworkUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  Go To Post <ExternalLinkIcon />
                </a>
              ) : undefined}
              {!!post?.errorCode && (
                <details>
                  <summary>
                    <Code>{post.errorCode}</Code>
                  </summary>
                  <Code>{post.errorMessage}</Code>
                </details>
              )}
            </Flex>
          </Td>
        </Tr>
      </Tbody>
    </Table>
  );
};

const Stats = ({ post }: { post: Post }) => {
  if (!post) {
    return <Skeleton w="100%" maxWidth="40ch" h="2em" />;
  }

  return <></>;
};

export default SocialPostPreview;
