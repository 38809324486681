import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import Button from 'web-react-ui/src/components/elements/Button';
import ImageField from 'web-react-ui/src/components/image/ImageField';
import View from 'web-react-ui/src/components/layout/View';
import LocaleSelector from 'web-react-ui/src/reactFinalForm/fields/localizableField/LocaleSelector';
import LocalizableSimpleForm from 'web-react-ui/src/reactFinalForm/fields/localizableField/LocalizableSimpleForm';
import LocalizableTextField from 'web-react-ui/src/reactFinalForm/fields/localizableField/LocalizableTextField';
import TextField from 'web-react-ui/src/reactFinalForm/fields/TextField';
import FormSubmit from 'web-react-ui/src/reactFinalForm/FormSubmit';
import {
  atLeastOneCompleteLocalization,
  forAllLocales,
  max,
  required
} from 'web-react-ui/src/reactFinalForm/validators';
import ErrorMessage from 'web-react-ui/src/components/entities/ErrorMessage';

import client from '../../services/client';
import PlatformTranslationWrapper from '../i18n/PlatformTranslationWrapper';

const renderTitle = (id, obj) => {
  if (id) {
    return obj && obj.name;
  }
  return 'Create Category';
};

class AdminCategoryEdit extends Component {
  static propTypes = {
    categoryId: PropTypes.string,
    history: PropTypes.object
  };

  state = {
    initialFormValues: {},
    isLoading: true,
    error: null
  };

  componentDidMount() {
    const { categoryId } = this.props;

    if (categoryId) {
      client.categories.editWithLocalization(categoryId)
        .then(values => this.setState({ initialFormValues: values, isLoading: false }))
        .catch(error => this.setState({ error, isLoading: false }));
    } else {
      this.setState({ initialFormValues: { images: [] }, isLoading: false });
    }
  }

  async saveCategory(instance, updates) {
    this.setState({ saving: true });

    // Hold on to created/updated category so we can eventually redirect to the details view
    // eslint-disable-next-line no-unused-vars
    const category = instance.id
      ? await client.categories.update(instance, updates)
      : await client.categories.create(updates);

    this.setState({ saving: false });

    this.back();
  }

  back() {
    const { history } = this.props;
    history.push('./');
  }
  render() {
    const { categoryId } = this.props;
    const { initialFormValues, isLoading, error } = this.state;
    return (
      <View>
        <View.Section narrow className='mt1'>
          <ErrorMessage error={error} />
        </View.Section>
        <PlatformTranslationWrapper>
          <LocalizableSimpleForm
            onSubmit={updates => this.saveCategory(initialFormValues, updates)}
            initialValues={initialFormValues}
            loading={isLoading}
            validateLocale={atLeastOneCompleteLocalization([
              'nameLocalized', 'shortNameLocalized'
            ])}
          >
            <View.Section>
              <div className='flex aic mt2 mb1'>
                <div className='flex aic jcsb'>
                  <Button
                    className='link'
                    icon='long arrow alternate left'
                    onClick={this.back.bind(this)}
                  />
                  <h2 className='mt0'>
                    {renderTitle(categoryId, initialFormValues)}
                  </h2>
                </div>
                <LocaleSelector inline className='mla' />
              </div>
            </View.Section>
            <View.Section>
              <LocalizableTextField
                label='Name'
                name='nameLocalized'
                placeholder='Name'
                validateLocale={forAllLocales(max(32))}
              />
              <LocalizableTextField
                label='Short Name'
                name='shortNameLocalized'
                placeholder='Short Name'
                validateLocale={forAllLocales(max(18))}
              />
              <div className='flex children-even mb2'>
                <div className='pr1'>
                  <ImageField
                    fileType='category-icon-small'
                    name='iconSmall'
                    label='Small Icon'
                    backgroundColor='#eee'
                    validate={required}
                  />
                </div>
                <div className='pl1'>
                  <ImageField
                    fileType='category-icon-large'
                    name='iconLarge'
                    label='Large Icon'
                    backgroundColor='#eee'
                    validate={required}
                  />
                </div>
              </div>
              <TextField name='parentId' label='Parent Id' />
              <FormSubmit label={categoryId ? 'Update' : 'Create'} ignoreValidity />
            </View.Section>
          </LocalizableSimpleForm>
        </PlatformTranslationWrapper>
      </View>
    );
  }
}

export default withRouter(AdminCategoryEdit);
