import {
  Button,
  Flex,
  Spacer,
} from '@chakra-ui/react';
import React from 'react';
import ColorField from 'web-react-ui/src/reactFinalForm/fields/ColorField';
import SimpleForm from 'web-react-ui/src/reactFinalForm/SimpleForm';
import { hexColor } from 'web-react-ui/src/reactFinalForm/validators';
import Business from 'web-react-ui/src/types/Business.interface';
import useRequest from 'shared/src/hooks/useRequest';
import client from '../../../../services/client';

interface FormData {
  branding: {
    colors: {
      primary: string
    }
  };
}

const updateBrand = (businessId: string) => async (profile: FormData) => {
  await client
    .businesses.for(businessId)
    .social.updateProfile(
      { branding: { colors: { primary: profile?.branding?.colors?.primary ?? null } } },
      {
        fields: [
          'name',
          'socialAccounts.type',
          'socialAccounts.linked',
          'timezone',
          'enabled',
          'created',
          'isLinked',
          'health',
          'branding',
        ],
      },
    );
  // Discard successful response
};

const BrandSettings = ({ business, profile }: { business: Business, profile: Object }) => {
  const updateRequest = useRequest(updateBrand(business.id));

  return (
    <SimpleForm
      initialValues={profile}
      onSubmit={updateRequest.run}
      loading={updateRequest.loading}
    >
      <Flex direction="column" gap="1em">
        <ColorField
          name="branding.colors.primary"
          validate={hexColor}
          label="Primary Color"
          info="Six-character Hex color (eg: #00FF99)"
        />
        <Flex>
          <Spacer />
          <Button type="submit" colorScheme="blue">Save</Button>
        </Flex>
      </Flex>
    </SimpleForm>
  );
};

export default BrandSettings;
