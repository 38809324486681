import React from 'react';
import { AddIcon } from '@chakra-ui/icons';
import { Box, Button, Flex, FormLabel, Heading, HStack, Icon, Input, Select } from '@chakra-ui/react';
import { FieldRenderProps, useField, useForm } from 'react-final-form';

import { Field } from 'web-react-ui/src/reactFinalForm';
import FieldError from 'web-react-ui/src/reactFinalForm/fields/FieldError';
import { regex } from 'web-react-ui/src/reactFinalForm/validators';

const ExternalIdTypes = [
  { value: '', label: 'Select' },
  { value: 'directwest', label: 'DirectWest Listing ID' },
  { value: 'yelp', label: 'Yelp Listing ID' },
  { value: 'google', label: 'Google Listing ID' },
  { value: 'osm', label: 'OSM Listing ID' },
];

const externalIdSchemas: { [key: string]: any } = {
  // https://wiki.openstreetmap.org/wiki/Elements
  osm: /^(way|node)\s\d+$/,
  // https://stackoverflow.com/questions/29201332/google-places-api-place-id-format
  google: /^[a-zA-Z0-9_-]+$/,
  // deduced by looking at example ids on yelp.com
  yelp: /^[a-zA-Z0-9_-]+$/,
  // inspecting source code for https://mysask411.com/saskatoon/brick-loft-event-co-ltd/1086895
  // <numbers>:<numbers>
  directwest: /^[0-9]+:[0-9]+$/
};

type ExternalIdsArray = Array<{ key: string, value?: string }>;

const ExternalIdFields = (): JSX.Element => {
  const form = useForm();
  const { input } = useField('externalIds', {});
  const externalIdFields: ExternalIdsArray = input.value;

  return (
    <Box pb={10}>
      <Heading fontSize='xl' fontWeight={500} mb={5}>
        External Listing IDs
      </Heading>
      {externalIdFields?.map((item, key) => {
        return (
          <Box key={`${key}_${item.key}`} mb={8}>
            <HStack spacing={6} align='flex-start'>
              <Box w='50%'>
                <FormLabel>Type</FormLabel>
                <Field
                  name={`externalIds[${key}].key`}
                  validateFields={[`externalIds[${key}].value`]}
                  key={item.key}
                >
                  {({ input: keyInput }: FieldRenderProps<any>) => (
                    <Select {...keyInput} value={item.key}>
                      {ExternalIdTypes.map(option => (
                        <option
                          key={option.value}
                          value={option.value}
                          disabled={!!externalIdFields.find(i => i.key === option.value)}
                        >
                          {option.label}
                        </option>
                      ))}
                    </Select>
                  )}
                </Field>
              </Box>
              <Box w='50%'>
                <Flex justify='space-between' align='center'>
                  <FormLabel>ID</FormLabel>
                  <Button
                    variant='link'
                    mb={2}
                    onClick={() => {
                      form.change(`externalIds`, externalIdFields.filter((i, k) => k !== key));
                    }}
                  >
                    Remove
                  </Button>
                </Flex>
                <Field
                  name={`externalIds[${key}].value`}
                  validate={regex(externalIdSchemas[item.key], 'Invalid ID for this listing type')}
                  key={item.key}
                >
                  {({ input: valueInput }: FieldRenderProps<any>) => {
                    return (
                      <>
                        <Input
                          {...valueInput}
                          value={item.value}
                          type='text'
                        />
                        <FieldError name={`externalIds[${key}].value`} alwaysShow={false} chakra />
                      </>
                    );
                  }}
                </Field>
              </Box>
              );
            </HStack>
          </Box>
        );
      })}
      {
        externalIdFields.length < ExternalIdTypes.length - 1 && (
          <Button
            variant='ghost'
            colorScheme='blue'
            fontSize='xl'
            leftIcon={<Icon as={AddIcon} />}
            onClick={() => {
              form.change('externalIds', [...externalIdFields, { key: '', value: '' }]);
            }}
          >
            Add ID
          </Button>
        )
      }
    </Box >
  );
};

export default ExternalIdFields;
