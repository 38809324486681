import React from 'react';
import { Button, HStack, Menu, MenuList, MenuButton, MenuItem, IconButton } from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';

type Action = {
  label: string;
  action: () => void;
};

const ActionMenu = ({
  actions,
  colorScheme = 'blue'
}: {
  actions: Action[],
  colorScheme?: string,
}): JSX.Element => {
  return (
    <HStack spacing={5}>
      <Button colorScheme={colorScheme} onClick={actions[0].action}>
        {actions[0].label}
      </Button>
      {actions.length > 1 && (
        <Menu>
          <MenuButton as={IconButton} icon={<HamburgerIcon />} variant='outline' />
          <MenuList>
            {actions.slice(1, actions.length).map((action: Action) => (
              <MenuItem key={action.label} onClick={action.action}>
                {action.label}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      )}
    </HStack>
  );
};

export default ActionMenu;
