import React from 'react';
import PropTypes from "prop-types";
import { Badge } from "@chakra-ui/react";
import {
  BooleanField,
  TextField
} from 'web-react-ui/src/reactFinalForm/fields';
import FieldLabel from 'web-react-ui/src/reactFinalForm/fields/FieldLabel';
import TextArea from 'web-react-ui/src/reactFinalForm/fields/TextArea';
import { required } from 'web-react-ui/src/reactFinalForm/validators';
import LanguageSelectorField from './LanguageSelectorField';


const formatJson = (value) => {
  try {
    return JSON.stringify(value, 0, 2);
  } catch (err) {
    return undefined;
  }
};

const parseJson = (str) => {
  try {
    return JSON.parse(str);
  } catch (err) {
    return undefined;
  }
};

const PropertyEditForm = ({ admin, status }) => {
  const statusColor = status === "live" ? "green" : "orange";
  return (
    <React.Fragment>
      <TextField fluid name='property.name' label='Name' placeholder='Name' validate={required} />
      <TextField
        name='property.subdomain'
        label='Domain'
        disabled
        fluid
        placeholder='subdomain'
        validate={required}
        inputProps={{ label: `.${process.env.REACT_APP_ROOT_DOMAIN}`, labelPosition: 'right' }}
      />
      <FieldLabel label="Status" className="mt1">
        <Badge colorScheme={statusColor} p="2">{status}</Badge>
      </FieldLabel>
      <TextField className='mt1' fluid name='settings.homepage__url' placeholder='Homepage' wrapLabel />
      <TextField
        className='mt1'
        fluid
        name='settings.mobile__ios__install_url'
        placeholder='Install Url iOS'
        wrapLabel
      />
      <TextField
        className='mt1 mb1'
        fluid
        name='settings.mobile__android__install_url'
        placeholder='Install Url Android'
        wrapLabel
      />

      <hr className="mv3" />

      <LanguageSelectorField
        name="settings.locales"
        label="Languages"
      />

      <hr className="mv3" />

      {admin && (
        <React.Fragment>
          <BooleanField toggle name="settings.business_dashboard__enabled" label="Business Dashboard" />
          <BooleanField toggle name="settings.self_signup__enabled" label="Self Signup" />
          <hr className="mv3" />
          <h2>Sharing</h2>
          <TextField fluid name='settings.template__share_url__offer' label='Offer Share Url' />
          <TextField fluid name='settings.template__share_url__business' label='Business Share Url' />
          <TextField fluid name='settings.template__share_url__location' label='Location Share Url' />
          <hr className="mv3" />
          <h2>Hosted Redemption</h2>
          <BooleanField toggle name="settings.hosted_redemption__enabled" label="Hosted Redemption" />
          <TextField fluid name='settings.template__redeem_url__offer' label='Offer Redeem Url' />
          <TextField fluid name='settings.hosted_redemption__integration_key' label='Integration Key' disabled />
          <TextArea
            format={formatJson}
            formatOnBlur
            parse={parseJson}
            fluid
            name='settings.hosted_redemption__theme'
            label='Theme'
          />
        </React.Fragment>
      )}

    </React.Fragment>
  );
};

PropertyEditForm.propTypes = {
  admin: PropTypes.bool,
  status: PropTypes.oneOf(['test', 'live']),
};

export default PropertyEditForm;
