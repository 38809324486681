import React from 'react';
import PropTypes from 'prop-types';

import ImageRenderer from './ImageRenderer';

const UserListProfileImage = ({ imageUrl, userName }) => (
  <ImageRenderer imageUrl={imageUrl} name={userName} size="lg" />
);

UserListProfileImage.propTypes = {
  imageUrl: PropTypes.string,
  userName: PropTypes.string
};

export default UserListProfileImage;
