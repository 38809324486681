import React, { useCallback } from 'react';
import PropTypes from "prop-types";
import { Field } from 'react-final-form';
import SuiForm from 'web-react-ui/src/components/collections/Form';
import Checkbox from 'web-react-ui/src/components/modules/Checkbox';
import FieldError from 'web-react-ui/src/reactFinalForm/fields/FieldError';
import FieldLabel from 'web-react-ui/src/reactFinalForm/fields/FieldLabel';
import { LANGUAGE_OPTIONS } from '../../i18n';

const LanguageSelectorField = ({ name, label }) => {
  return (
    <Field name={name}>
      {({ input }) => {
        const { value: fieldValue, onChange } = input;

        const handleToggle = useCallback((_, { option }) => {
          if (fieldValue.includes(option)) {
            onChange(fieldValue.filter(v => v !== option));
          } else {
            onChange(fieldValue.concat(option));
          }
        });

        const handleSetDefault = useCallback((option) => {
          onChange([option].concat(fieldValue.filter(v => v !== option)));
        });

        return (
          <SuiForm.Field>
            <FieldLabel label={label} />
            <div>
              {LANGUAGE_OPTIONS.map(({ text, value }) => {
                const isChecked = fieldValue.includes(value);
                const isDefault = fieldValue[0] === value;
                const defaultButton = isDefault
                  ? (<em>Default</em>)
                  : (<a onClick={() => handleSetDefault(value)}>Set as default</a>);

                return (
                  <div key={value} className="flex mv1">
                    <Checkbox
                      toggle
                      checked={isChecked}
                      disabled={isDefault}
                      onClick={handleToggle}
                      option={value}
                      label={text}
                      className="mra"
                    />
                    {defaultButton}
                  </div>
                );
              })}
            </div>
            <FieldError name={name} />
          </SuiForm.Field>
        );
      }}
    </Field>
  );
};

LanguageSelectorField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
};

export default LanguageSelectorField;
