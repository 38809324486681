import {
  Box,
  Divider,
  Flex,
  Grid,
  HStack,
  Skeleton,
  SkeletonCircle,
  SkeletonText,
} from '@chakra-ui/react';
import React from 'react';
import StatusBadge from 'shared/src/components/StatusBadge';
import SkeletonSocialPostCard from './SkeletonSocialPostCard';

const SkeletonDetailView = () => {
  return (
    <Grid
      as="div"
      m="4rem"
      className="_skeletonMainContainer"
      display="grid"
      templateColumns={{ base: '1fr', md: '1fr 1fr', lg: '5fr 2.5fr' }}
      h="70svh"
      gap={6}
    >
      <Box display="flex" flexDirection="column">
        <Skeleton maxW="60ch" w="100%" h="24px" mb="2rem" />
        <Flex
          wrap="wrap"
          direction={{ base: 'column', md: 'row' }}
          gap="3em"
          w="100%"
          className="_postDetailContainer"
        >
          <Flex
            direction="column"
            gap="1em"
            flex="1"
            minW="40ch"
            alignItems="start"
          >
            <Box w="240px" display="grid" gap={4}>
              <Skeleton h="12px" />
              <Skeleton h="12px" />
            </Box>
            <Box>
              <SkeletonText mt="4" noOfLines={1} spacing="4" />
              <Divider />
            </Box>
            <Divider />
            <Skeleton h="24px" w="30ch" mb="8" />
            <Box display="flex" flexDirection="column" w="100%" gap={4}>
              <Divider />
              <Box display="flex" gap={2}>
                <SkeletonCircle size="12" />
                <SkeletonText
                  mt="2"
                  noOfLines={2}
                  h="16px"
                  maxW="30ch"
                  flexGrow={1}
                  spacing="4"
                />
              </Box>
              <Divider />
            </Box>
            <Box display="flex" flexDirection="column" w="100%" gap={4}>
              <Divider />
              <Box display="flex" gap={2}>
                <SkeletonCircle size="12" />
                <SkeletonText
                  mt="2"
                  noOfLines={2}
                  h="16px"
                  maxW="30ch"
                  flexGrow={1}
                  spacing="4"
                />
              </Box>
              <Divider />
            </Box>
          </Flex>
        </Flex>
      </Box>
      <Box maxH="60rem">
        <SkeletonSocialPostCard />
      </Box>
    </Grid>
  );
};

export default SkeletonDetailView;
