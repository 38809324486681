/* eslint class-methods-use-this: 0, jsx-a11y/accessible-emoji: 0 */
import React, {
  Fragment,
  useCallback
} from 'react';
import { Link } from 'react-router-dom';

import { useQuerystringParam } from 'shared/src/hooks/useQuerystringParam';

import Button from 'web-react-ui/src/components/elements/Button';
import View from 'web-react-ui/src/components/layout/View';
import SearchBase from 'web-react-ui/src/components/newSearchBase/SearchBase';
import Request from 'web-react-ui/src/data/Request';
import WaitFor from 'web-react-ui/src/data/WaitFor';
import Map from 'web-react-ui/src/map/Map';

import client from '../../services/client';

const searchRegions = query => client.regions.search({ query, includeArea: true })
  .then(pList => pList.items);

const detailFields = [
  'neighbourhood',
  'city',
  'state',
  'county',
  'country',
  'locality',
  'centre'
];

const mapRegion = (region) => {
  if (!(region && region.area)) return false;
  return {
    area: region.area.map(([lat, long]) => [long, lat])
  };
};

const AdminRegions = () => {
  const [regionId, setRegionId] = useQuerystringParam('region');
  const fetchRegionDetails = useCallback(({ regionId: selectedRegionId }) => {
    if (!selectedRegionId) return null;
    return client.regions.for(selectedRegionId).details({ includeArea: true });
  });

  return (
    <View>
      <View.Section>
        <div className='flex aic'>
          <h1>Regions</h1>
          <Button
            as={Link}
            to='/regions/add'
            primary
            className='mla'
          >
            Create Region
          </Button>
        </div>

        <label>
          Find region...
          <SearchBase
            search={searchRegions}
            getLabel={i => (i ? i.label : '')}
            onChange={region => setRegionId(region.id)}
          />
        </label>
      </View.Section>

      <View.Section>
        <Request request={fetchRegionDetails} params={{ regionId }}>
          {({ data: region, isLoading }) => (
            <WaitFor waitFor={!isLoading}>
              {!!region && (
                <React.Fragment>
                  <div className='flex'>

                    <div style={{ width: '50%' }}>
                      <dl>
                        {detailFields.map(name => (
                          <Fragment key={name}>
                            <dt>{name}</dt>
                            <dd>{region[name]}</dd>
                          </Fragment>
                        ))}
                      </dl>
                    </div>
                  </div>
                  <Map regions={[mapRegion(region)]} />
                </React.Fragment>
              )}
            </WaitFor>
          )}
        </Request>
      </View.Section>

    </View>
  );
};

export default AdminRegions;
