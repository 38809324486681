import React from 'react';
import { connect } from 'react-redux';

import AdminBusinessLocationsView from './AdminBusinessLocationsView';
import businessesModule from "../../modules/businesses";

const mapState = state => ({
  business: businessesModule.selectors.business.getData(state)
});

export default connect(mapState)(props => <AdminBusinessLocationsView {...props} />);
