import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Button from 'web-react-ui/src/components/elements/Button';

import Input from 'web-react-ui/src/components/elements/Input';
import client from '../services/client';

const OSM_URL_REGEX = /(node|way)[/=](\d+)/;

class AddressOsmIdSearch extends Component {
  state = {};

  async fetchNode() {
    const osmUrl = this.state.osmUrl;
    const [, osmType, osmId] = OSM_URL_REGEX.exec(osmUrl) || [];

    if (!osmType || !osmId) return;

    try {
      const nodes = await client.addresses.discover({ q: `${osmType} ${osmId}` });
      const address = nodes.items[0];
      if (address) this.props.onChange(address);
    } catch (err) {
      console.error(err); /* eslint-disable-line no-console */
    }
  }

  render() {
    const { placeholder } = this.props;
    return (
      <div className="flex">
        <Input onChange={event => this.setState({ osmUrl: event.target.value })} placeholder={placeholder}/>
        <Button type='button' onClick={this.fetchNode.bind(this)}>Find</Button>
      </div>
    );
  }
}

AddressOsmIdSearch.propTypes = {
  onChange: PropTypes.func,
  placeholder: PropTypes.string
};

export default AddressOsmIdSearch;
