/* eslint class-methods-use-this: 0 */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ActionMenu from 'web-react-ui/src/components/actionMenu/ActionMenu';
import Message from 'web-react-ui/src/components/collections/Message';
import View from 'web-react-ui/src/components/layout/View';
import Request from 'web-react-ui/src/data/Request';
import WaitFor from 'web-react-ui/src/data/WaitFor';

import client from '../../services/client';
import RelativeLink from '../routing/RelativeLink';
import confirm from "../../services/confirm";

const Presenter = ({ property, handleDelete, isBusy }) => (
  <WaitFor waitFor={!isBusy}>
    <View>
      <View.Section>
        <div>
          <div className={'pull-right'}>
            <ActionMenu
              actions={[
                {
                  label: 'Edit',
                  extraProps: {
                    as: RelativeLink,
                    to: './edit'
                  }
                },
                {
                  label: 'Delete Property',
                  action: () => handleDelete(property),
                  negative: true
                }
              ]}
            />
          </div>
          <h1 className='mt2'>{property && property.name}</h1>
        </div>
      </View.Section>
      <View.Section divided>
        <div className='flex'>
          <h6>Subdomain:</h6>
          <p className='ml1'>{property && property.subdomain}</p>
        </div>
        {(property && property.homepage) && (
          <div className='flex'>
            <h6>HomePage:</h6>
            <p className='ml1'>{property && property.homepage}</p>
          </div>
        )}
        {(property && property.installUrlAndroid) && (
          <div className='flex'>
            <h6>Install Url Android:</h6>
            <p className='ml1'>{property && property.installUrlAndroid}</p>
          </div>
        )}
        {(property && property.installUrlIos) && (
          <div className='flex'>
            <h6>Install Url iOS:</h6>
            <p className='ml1'>{property && property.installUrlIos}</p>
          </div>
        )}
      </View.Section>
    </View>
  </WaitFor>
);
Presenter.propTypes = {
  property: PropTypes.object,
  handleDelete: PropTypes.func,
  isBusy: PropTypes.bool
};


class AdminPropertyDetails extends Component {
  static propTypes = {
    propertyId: PropTypes.string
  };

  state = {};

  fetchProperty() {
    const { propertyId } = this.props;
    return client.properties.for(propertyId).details();
  }

  // FIXME check that this works when platform admin has permission to delete a property.
  handleDelete(property) {
    if (!confirm(`Are you sure you want to delete ${property.name}?`)) return false;
    this.setState({ isBusy: true });
    return client
      .properties.for(property.id)
      .delete()
      .then(() => {
        const adminPropertyListUrl = `/properties`;
        this.setState({ redirect: adminPropertyListUrl });
      })
      .catch(error => this.setState({ error, isBusy: false }));
  }

  render() {
    return (
      <Request request={this.fetchProperty.bind(this)}>
        {({ data, loading, error }) => (
          <WaitFor waitFor={!loading}>
            {error && <Message error>{error.message}</Message>}
            {this.state.error && <Message error>{this.state.error.message}</Message>}
            <Presenter isBusy={this.state.isBusy} property={data} handleDelete={this.handleDelete.bind(this)} />
          </WaitFor>
        )}
      </Request>
    );
  }
}

export { Presenter };
export default AdminPropertyDetails;
