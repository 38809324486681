/* eslint-disable max-len */
/* eslint-disable-next-line no-redeclare */
/* globals window */

// const osmApiBase = 'https://master.apis.dev.openstreetmap.org/api/0.6';
const osmApiBase = 'https://www.openstreetmap.org/api/0.6';

const osmFetch = async (url, options) => {
  const res = await window.fetch(`${osmApiBase}${url}`, options);
  if (!res.ok) throw res;
  return res.text();
};

const osmFetchXml = async (url, options) => {
  const xmlString = await osmFetch(url, options);
  return new window.DOMParser().parseFromString(xmlString, `text/xml`);
};


const getElement = (elementId, elementType) => osmFetchXml(`/${elementType}/${elementId}`);


const createChangeset = elementNode => osmFetch(
  `/changeset/create`,
  {
    method: 'PUT',
    body: `<osm>
      <changeset version="0.6">
        <tag k="comment" v="Update ${elementNode.id}"/>
        <tag k="created_by" v="LMG Portal"/>
      </changeset></osm>`
  }
);


const updateChangeset = (changesetId, elementDoc, changes) => {
  const elementType = elementDoc.nodeName;
  const elementId = elementDoc.id;
  const elementVersion = elementDoc.getAttribute('version');
  const lon = elementDoc.getAttribute('lon');
  const lat = elementDoc.getAttribute('lat');

  const filteredChanges = Object.fromEntries(Object.entries(changes)
    .filter(([, v]) => Boolean(v) || typeof v === 'number'));

  const existingTags = Array.from(elementDoc.querySelectorAll('tag'))
    .reduce((acc, tagEl) => {
      acc[tagEl.getAttribute('k')] = tagEl.getAttribute('v');
      return acc;
    }, {});

  const allTags = Object.assign(existingTags, filteredChanges);

  const body = `
        <osmChange version="0.6">
          <modify>
            <${elementType} id="${elementId}" ${lon ? `lon="${lon}"` : ''} ${lat ? `lat="${lat}"` : ''} version="${elementVersion}" changeset="${changesetId}">
              ${Object.entries(allTags).map(([key, value]) => `<tag k="${key}" v="${value}"/>`).join('')}
            </${elementType}>
          </modify>
        </osmChange>
      `;

  return osmFetchXml(
    `/changeset/${changesetId}/upload`,
    {
      method: 'POST',
      body
    }
  );
};


const closeChangeset = changesetId => osmFetch(`/changeset/${changesetId}/close`, { method: 'PUT' });


const updateElementTags = async (elementType, elementId, tags) => {
  const elementDoc = await getElement(elementId, elementType);
  const elementNode = elementDoc.querySelector('node,way');
  const changesetId = await createChangeset(elementNode);

  await updateChangeset(changesetId, elementNode, tags);

  return closeChangeset(changesetId);
};

/* eslint-disable import/prefer-default-export */
export { updateElementTags };
