import CancelablePromise from 'shared/src/modules/promise/CancelablePromise';
import { types } from './types';

const fetchBusiness = async (client, propertyId, businessId) => {
  const businessRepo = propertyId
    ? client
      .properties.for(propertyId)
      .businesses.for(businessId)
    : client
      .businesses.for(businessId);

  const business = await businessRepo.detailsForAdmin();

  const address = await client
    .properties.for(process.env.REACT_APP_DEFAULT_PROPERTY)
    .businesses.for(businessId)
    .getBillingAddress()
    .catch(() => null); // If it doesn't have an address, don't blow up

  business.billingAddress = address;


  return business;
};

let pending;

const businessMiddleware = client => store => next => async (action) => {
  const { type, args } = action;
  const { businessId, propertyId } = args || {};
  switch (type) {
    case types.BUSINESS_INVALIDATE:
      pending.cancel();
      pending = null;
      return next(action); // Let reducer handle it too
    case types.BUSINESS_DETAILS_REQUEST:
      // already loading for this action, ignore request
      if (pending?.action.args.businessId === businessId) {
        return null;
      }

      // pending promise, not yet settled, cancel it
      if (pending && !pending.isSettled) {
        pending.cancel();
      }

      pending = new CancelablePromise(fetchBusiness(client, propertyId, businessId), action);

      return pending.promise.then((entity) => {
        store.dispatch({
          type: types.BUSINESS_DETAILS_SUCCESS,
          payload: {
            data: entity,
          }
        });
        return entity;
      }).catch((error) => {
        if (error.message === CancelablePromise.CANCELED) return null;

        if (pending.isCanceled === action.id) {
          return null;
        }

        store.dispatch({
          type: types.BUSINESS_DETAILS_FAILURE,
          error,
        });
        return error;
      });
    case types.BUSINESS_INVALIDATE_REFRESH:
      return store.dispatch({
        type: types.BUSINESS_INVALIDATE
      }).then(() => {
        store.dispatch({
          type: types.BUSINESS_DETAILS_REQUEST,
          args: { propertyId, businessId }
        });
      });
    default:
      return next(action);
  }
};

export default businessMiddleware;
