import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import Button from 'web-react-ui/src/components/elements/Button';
import Icon from 'web-react-ui/src/components/elements/Icon';
import Input from 'web-react-ui/src/components/elements/Input';
import List from 'web-react-ui/src/components/elements/List';
import ProfileImage from 'web-react-ui/src/components/image/ProfileImage';
import View from 'web-react-ui/src/components/layout/View';
import PagedList from 'web-react-ui/src/components/pagedList/PagedList';
import WaitFor from 'web-react-ui/src/data/WaitFor';

import client from '../../services/client';
import RelativeLink from '../routing/RelativeLink';

const CategoryListItem = ({ category }) => (
  <List.Item as={RelativeLink} to={`./${category.id}`}>
    <div className="flex aic">
      <List.Content>
        <ProfileImage imageId={category.iconSmall} name={category.name} />
      </List.Content>
      <List.Content className='mh1'>
        <List.Header>
          {category.name}
        </List.Header>
      </List.Content>
      <List.Content>
        {category.isLeaf && <Icon name='leaf' />}
      </List.Content>
    </div>
  </List.Item>
);

CategoryListItem.propTypes = {
  category: PropTypes.object
};

class AdminCategories extends Component {
  static propTypes = {
    history: PropTypes.object
  };

  state = {};

  handleSearchChange({ target: { value } }) {
    this.setState({ query: value });
  }

  handleCreate() {
    const { history } = this.props;
    history.push('/categories/add');
  }

  listCategories = async ({ query }) => {
    let categoriesList = {};
    if (query) {
      categoriesList = await client.categories.search({ query });
    } else {
      categoriesList = await client.categories.list();
    }
    return categoriesList;
  }

  render() {
    return (
      <View>
        <View.Section className='mt3 flex ais'>
          <Input
            type='search'
            fluid
            onChange={this.handleSearchChange.bind(this)}
            icon='search'
            placeholder='Find a Category...'
            className='fg1 mr1'
          />
          <Button onClick={this.handleCreate.bind(this)} primary>
            Create
          </Button>
        </View.Section>
        <View.Section>
          <PagedList fetchList={this.listCategories.bind(this)} params={{ query: this.state.query }}>
            {({ items, loading }) => (
              <WaitFor waitFor={!loading}>
                <List celled>
                  {items.map(category => (
                    <CategoryListItem
                      key={category.id}
                      category={category}
                    />
                  ))}
                </List>
              </WaitFor>
            )}
          </PagedList>
        </View.Section>
      </View>
    );
  }
}

export default withRouter(AdminCategories);
