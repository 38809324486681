import {
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay
} from '@chakra-ui/react';
import React, {
  useEffect,
  useState
} from 'react';
import { useHistory } from 'react-router-dom';
import { useI18Next } from 'shared/src/components/contexts/I18NextContext';
import SearchBusinessName from './SearchBusinessName';

const CheckBusinessContainer = () => {
  const { strings } = useI18Next();
  const history = useHistory();

  const [confirmedName, setConfirmedName] = useState('');

  useEffect(
    () => {
      // eslint-disable-next-line no-useless-return
      if (!confirmedName) return;

      // eslint-disable-next-line no-undef
      const url = new URL(window.location.href);
      url.searchParams.set('n', confirmedName);
      history.push(`/businesses/add${url.search}`);
    },
    [confirmedName]
  );

  return (
    <Modal isOpen={true} size="full">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader position="sticky" top={0} bg="white" borderRadius={8} zIndex={1}>
          <Heading fontSize="3xl" textAlign="center">
            {`${strings('ui.component.wizardModal.adding')} ${strings('ui.component.wizardModal.aNewBusiness')}`}
          </Heading>
        </ModalHeader>
        <ModalBody>
          <SearchBusinessName setConfirmedName={setConfirmedName} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CheckBusinessContainer;
