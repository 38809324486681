import React from 'react';
import PropTypes from 'prop-types';

import SearchBase from 'web-react-ui/src/components/newSearchBase/SearchBase';
import client from '../services/client';

const searchAddresses = (query) => {
  if (!query) return null;

  return client.addresses.discover({ q: query })
    .then(addresses => addresses.items);
};

const AddressSearch = (props) => {
  const { onSearchChange, value, ...rest } = props;
  return (
    <SearchBase search={searchAddresses} getLabel={v => (v ? v.label : '')} {...rest} />
  );
};

AddressSearch.propTypes = {
  onSearchChange: PropTypes.func,
  value: PropTypes.any, // ignore,
  placeholder: PropTypes.string,
};

export default AddressSearch;
