import React from 'react';
import { Link } from 'react-router-dom';

import Icon from 'web-react-ui/src/components/elements/Icon';
import List from 'web-react-ui/src/components/elements/List';
import View from 'web-react-ui/src/components/layout/View';

const ListItem = ({ path, label }: { path: string, label: string }): JSX.Element => (
  <List.Item as={Link} to={path}>
    <List.Content floated='right'>
      <Icon className="long arrow alternate right mt1" />
    </List.Content>
    <List.Content className='m1'>
      <List.Header>{label}</List.Header>
    </List.Content>
  </List.Item>
);

const AdminLanding = (): JSX.Element => (
  <View>
    <View.Section className='mt4'>
      <List celled verticalAlign='middle' selection>
        <ListItem path={`/businesses`} label="Businesses" />
        <ListItem path={`/users`} label="Users" />
        <ListItem path={`/properties`} label="Properties" />
        <ListItem path={`/regions`} label="Regions" />
        <ListItem path={`/addresses`} label="Addresses" />
        <ListItem path={`/categories`} label="Categories" />
        <ListItem path={`/social`} label="Social" />
      </List>
    </View.Section>
  </View>
);

export default AdminLanding;
