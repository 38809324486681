/* eslint class-methods-use-this:0 */
import { ExternalLinkIcon } from '@chakra-ui/icons';
import {
  Box,
  Divider,
  Flex,
  Heading,
  Spacer,
  Spinner,
  Text
} from '@chakra-ui/react';
import React, {
  useEffect,
  useState
} from 'react';
import { Link } from 'react-router-dom';
import useRequest from 'shared/src/hooks/useRequest';

import { useI18Next } from 'shared/src/components/contexts/I18NextContext';

import {
  List,
  Item
} from 'web-react-ui/src/chakra/List';
import Label from 'web-react-ui/src/components/elements/Label';
import ProfileImage from 'web-react-ui/src/components/image/ProfileImage';
import {
  TextField
} from 'web-react-ui/src/reactFinalForm/fields';
import CheckboxField from 'web-react-ui/src/reactFinalForm/fields/CheckboxField';
import FormSubmit from 'web-react-ui/src/reactFinalForm/FormSubmit';
import SimpleForm from 'web-react-ui/src/reactFinalForm/SimpleForm';
import View from 'web-react-ui/src/components/layout/View';

import client from '../../../services/client';

const searchBusinesses = async ({ query }) => {
  if (!query) return null;
  const [attached, notAttached] = await Promise.all([
    client
      .properties.for(process.env.REACT_APP_DEFAULT_PROPERTY)
      .businesses.list({ query }),
    client
      .properties.for(process.env.REACT_APP_DEFAULT_PROPERTY)
      .businesses.listNotAttached({ query })
  ]);

  return attached.copy(items => [...items, ...notAttached.items]);
};

function SearchBusinessName({ setConfirmedName }) {
  const { strings } = useI18Next();

  const [searchName, setSearchName] = useState();

  const searchRequest = useRequest(
    searchBusinesses,
    { query: searchName }
  );

  useEffect(
    () => {
      if (searchRequest.settled && !searchRequest.error && searchRequest.result.items.length === 0) {
        // Auto-advance if there are no Businesses with a matching name
        setConfirmedName(searchName);
      }
    },
    [searchRequest]
  );

  return (
    <View>
      <View.Section narrow>
        <SimpleForm onSubmit={formData => setSearchName(formData.searchName)}>
          <TextField
            name="searchName"
            label="Business Name"
            wrapLabel
          />
        </SimpleForm>
      </View.Section>
      {searchRequest.loading && (
        <View.Section narrow>
          <Flex justify="center">
            <Spinner size="xl" />
          </Flex>
        </View.Section>
      )}
      {searchRequest.settled && (
        <SimpleForm onSubmit={() => setConfirmedName(searchName)} validate={validateChecked}>
          <View.Section narrow>
            <Box mb="8">
              <Text as="b">Existing Businesses</Text>
              <Text>Some businesses with a similar or same name already exist, such as:</Text>
            </Box>
            <Divider />
            <Box mb="8">
              <List>
                {
                  searchRequest.result?.items?.map(business => (
                    <Item
                      key={business.id}
                      as={Link}
                      to={`/businesses/${business.id}`}
                      align="center"
                      target="_blank"
                    >
                      <Box mr={4}>
                        <ProfileImage imageId={business.profileImage} name={business.name} />
                      </Box>
                      <Text
                        fontWeight={500}
                        fontSize="lg"
                        color="black"
                        m={0}
                      >
                        {business.name}
                      </Text>
                      <Box ml="8" mr="8">
                        {(!business.isEnabled) && <Label>Disabled</Label>}
                      </Box>
                      <Spacer />
                      <ExternalLinkIcon />
                    </Item>
                  ))
                }
              </List>
            </Box>
            <CheckboxField name="confirmed">
              <Text>
                I confirm I have checked, and this should be a new business,
                not an additional location on an existing business
              </Text>
            </CheckboxField>
          </View.Section>
          <View.Section narrow className="flex">
            <FormSubmit label={strings('ui.label.next')} className="mla" />
          </View.Section>
        </SimpleForm>
      )}
    </View>
  );
}

const validateChecked = (formValues) => {
  console.log('formValues', formValues);
  if (formValues.confirmed) return undefined;
  return new Error('Please confirm you have checked any matching businesses');
};

export default SearchBusinessName;
