import {
  ChevronDownIcon
} from '@chakra-ui/icons';
import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuGroup,
  MenuList,
  Text
} from '@chakra-ui/react';
import React from 'react';
import {
  useSelector
} from 'react-redux';
import auth from 'shared/src/modules/auth';
import AccountMenu from './AccountMenu';

const MenuHeader = ({ user }) => {
  if (!user) return null;
  return (
    <MenuGroup title={
      <Box my="1em">
        <Text fontWeight="bold">{user.name}</Text>
        <Text fontColor="silver">{user.email}</Text>
      </Box>
    } />
  );
};

const SiteMenu = () => {
  const user = useSelector(auth.selectors.getUser);

  return (
    <Menu>
      <MenuButton
        as={Button}
        variant="simple"
        color="white"
        rightIcon={<ChevronDownIcon />}
        p="2em"
      >
        Settings
      </MenuButton>
      <MenuList>
        <MenuHeader user={user} />
        <AccountMenu user={user} />
      </MenuList>
    </Menu>
  );
};

export default SiteMenu;
