/* eslint class-methods-use-this: 0, jsx-a11y/accessible-emoji: 0 */
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import usePromise from 'shared/src/hooks/usePromise';

import View from 'web-react-ui/src/components/layout/View';
import SearchBase from 'web-react-ui/src/components/newSearchBase/SearchBase';
import WaitFor from 'web-react-ui/src/data/WaitFor';
import Map from 'web-react-ui/src/map/Map';
import TextField from 'web-react-ui/src/reactFinalForm/fields/TextField';
import FormSubmit from 'web-react-ui/src/reactFinalForm/FormSubmit';
import SimpleForm from 'web-react-ui/src/reactFinalForm/SimpleForm';
import client from '../../services/client';


const discoverRegions = query => client.regions.legacy.searchAdmin({ q: query })
  .then(pList => pList.items);

const mapRegion = (region) => {
  if (!(region && region.area)) return false;
  return {
    area: region.area.map(([lat, long]) => [long, lat])
  };
};

const AdminRegionsCreate = ({ history }) => {
  const [region, setRegion] = useState();

  const [createRegion, isCreating] = usePromise(
    async (formData) => {
      const newRegion = await client.regions.create({
        ...formData,
        area: region.area
      });

      history.push(`/regions?region=${newRegion.id}`);
    },
    [region]
  );

  return (
    <View>
      <WaitFor waitFor={!isCreating} wrapContents>
        <View.Section>
          <h1>Add Region</h1>

          <label>
            Search OSM...
            <SearchBase
              search={discoverRegions}
              getLabel={i => (i ? i.label : '')}
              onChange={selectedRegion => setRegion(selectedRegion)}
            />
          </label>
        </View.Section>

        {!!region && (
          <View.Section>
            <SimpleForm initialValues={region} onSubmit={createRegion}>
              <TextField name='neighbourhood' label='Neighbourhood' />
              <TextField name='city' label='City' />
              <TextField name='state' label='State' />
              <TextField name='county' label='County' />
              <TextField name='country' label='Country' />
              <TextField name='locality' label='Locality' />
              <Map regions={[mapRegion(region)]} initialCenter={[region.raw.lat, region.raw.lon]} />
              <div className='flex'>
                <FormSubmit label='Create' className='mla' />
              </div>
            </SimpleForm>
          </View.Section>
        )}
      </WaitFor>
    </View>
  );
};

AdminRegionsCreate.propTypes = {
  history: PropTypes.object
};

export default AdminRegionsCreate;
