import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import usePromise from 'shared/src/hooks/usePromise';
import SelectField from './SelectField';

function AsyncSelectField({ fetchOptions, autoSelectFirst, ...rest }) {
  const [fetch, loading, options] = usePromise(fetchOptions, [fetchOptions], []);
  useEffect(
    () => {
      fetch();
    },
    [fetch]
  );
  return <SelectField options={options} loading={loading} autoSelectFirst={autoSelectFirst} {...rest} />;
}

AsyncSelectField.propTypes = {
  fetchOptions: PropTypes.func,
  autoSelectFirst: PropTypes.bool
};

export default AsyncSelectField;
