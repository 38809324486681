/* Prices based on total tax rate for each province */
/* `1200 - (1200 * taxRate)` */
const CDAP_TAX_INCLUSIVE_PRICES = {
  BC: 1142.86, // 0.05
  AB: 1142.86, // 0.05
  SK: 1081.08, // 0.11
  MB: 1142.86, // 0.05
  ON: 1061.95, // 0.13
  QC: 1043.70, // 14.975
  NL: 1043.48, // 0.15
  NB: 1043.48, // 0.15
  NS: 1043.48, // 0.15
  PE: 1043.48, // 0.15
  YK: 1142.86, // 0.05
  NT: 1142.86, // 0.05
  NU: 1142.86, // 0.05
};

const cadCdapCustomer = {
  value: 'cdap-social-ai',
  label: 'CDAP Social AI',
  plans: [
    {
      value: 'cdap-social-ai',
      label: 'CDAP Social AI',
      plan: {
        chargebeePlanId: 'enterprise-v2-cdap-social-ai-annual-cad',
        currencyCode: 'CAD',
        affiliateCode: 'ns',
        autoCollection: 'off',
        defaultPrice: 1200,
        autoPrice: ({ provinceCode }) => CDAP_TAX_INCLUSIVE_PRICES[provinceCode] || 1200
      }
    }
  ]
};

export default cadCdapCustomer;
