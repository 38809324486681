import {
  Flex,
  Text
} from '@chakra-ui/react';
import plans from 'business-dashboard/src/assets/data/plans';
import React, { useState } from 'react';
import {
  RootStateOrAny,
  useSelector
} from 'react-redux';
import { useHistory } from 'react-router-dom';
import { COUNTRIES } from 'shared/src/constants/countries';
import useQuerystring from 'shared/src/hooks/useQuerystring';
import authModule from 'shared/src/modules/auth';
import WizardModal from 'web-react-ui/src/components/wizard/WizardModal';
import createWizard from './wizard/CreateBusinessWizard';

const createInitialBusiness = (query) => {
  return {
    token: query.t,
    name: query.n,
    shortName: query.n?.substring(0, 24),
    url: query.ur,
    attributionCode: query.at
  };
};

const createInitialAddress = (query) => {
  const country = COUNTRIES.find(c => c.keyName === query.co);
  const state = country?.regions.find(region => region.id === query.pr);
  return {
    country: country?.value,
    city: query.ci,
    url: query.ur,
    phone: query.p,
    streetAddress: query.sa,
    street: query.st,
    suite: query.su,
    state: state?.value,
    postalcode: query.pc
  };
};

const CreateBusinessView = () => {
  const [query] = useQuerystring();
  const [business] = useState(createInitialBusiness(query));
  const [address] = useState(createInitialAddress(query));
  const user = useSelector(authModule.selectors.getUser);

  const history = useHistory();

  const [businessManager] = useState(
    () => {
      const businessManagerInstance = createWizard({
        property: { id: process.env.REACT_APP_DEFAULT_PROPERTY },
        business,
        address,
        user,
        locale: 'en',
        onComplete: (finalBusiness, planDetails) => {
          // If the planDetails were never set, it's because we didn't specify any.
          // We didn't specify any because the Business was created from a token.
          // It was created from a token because the Subscription was already created through the Partner Intake form.
          if (!planDetails) {
            return history.push(`/businesses/${finalBusiness.id}`);
          }

          // eslint-disable-next-line no-undef
          const url = new URL(window.location);
          url.pathname = `/businesses/add/complete/${finalBusiness.id}`;
          url.searchParams.set('plan', planDetails.chargebeePlanId);
          url.searchParams.set('affiliateCode', planDetails.affiliateCode);
          if (planDetails.customPrice) {
            url.searchParams.set('price', planDetails.customPrice);
          }
          if (planDetails.includeOnboardingFee) {
            url.searchParams.set('onboardingFee', planDetails.onboardingFee);
          }
          if (planDetails.autoCollection) {
            url.searchParams.set('autoCollection', planDetails.autoCollection);
          }
          if (planDetails.coupons) {
            url.searchParams.set('coupons', planDetails.coupons);
          }
          if (planDetails.freePeriod) {
            url.searchParams.set('freePeriod', planDetails.freePeriod);
          }
          if (planDetails.freePeriodUnit) {
            url.searchParams.set('freePeriodUnit', planDetails.freePeriodUnit);
          }

          return history.push(`${url.pathname}/${url.search}`);
        }
      });

      businessManagerInstance.start();

      return businessManagerInstance;
    },
    []
  );
  return (
    <WizardModal
      manager={businessManager}
      plansViewModel={plans}
      submittingChildren={
        <Flex flexDirection="column">
          <Text textAlign="center">
            Creating Business
          </Text>
          <Text textAlign="center">
            Please do not close the browser...
          </Text>
        </Flex>
      }
    />
  );
};

export default CreateBusinessView;
