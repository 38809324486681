/* eslint class-methods-use-this: 0 */
import { Box } from '@chakra-ui/react';
import React, { Component } from 'react';
import Image from 'web-react-ui/src/components/elements/Image';
import Divider from 'web-react-ui/src/components/elements/Divider';

import LMGlogoGrey from '../../assets/LMGlogoGrey.png';

export default class SiteFooter extends Component {
  render() {
    return (
      <Box mt="2em" w="100%">
        <Image src={LMGlogoGrey} alt="logo" />
        <Divider my="1em"/>
      </Box>
    );
  }
}
