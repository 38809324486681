import {
  Button,
  Flex,
} from '@chakra-ui/react';
import React, {
  useEffect,
  useState
} from 'react';
import {
  useField
} from 'react-final-form';
import { TextField } from 'web-react-ui/src/reactFinalForm/fields';

const HubspotIdField = () => {
  const [open, setOpen] = useState(false);

  // Show fields if we return here and values were already set
  const hubspotValues = useField('hubspot').input.value;
  useEffect(
    () => {
      if (!open && hubspotValues) {
        setOpen(true);
      }
    },
    [open, hubspotValues]
  );

  if (!open) {
    return (
      <Flex direction="column" align="start">
        <Button variant="link" onClick={() => setOpen(true)}>+ Add Hubspot IDs</Button>
      </Flex>
    );
  }

  return (
    <Flex direction="column">
      <TextField
        wrapLabel
        name="hubspot.companyId"
        label="Hubspot Company ID"
        optional
      />
      <TextField
        wrapLabel
        name="hubspot.dealId"
        label="Hubspot Deal ID"
        optional
      />
    </Flex>
  );
};

export default HubspotIdField;
