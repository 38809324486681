import {
  Flex,
  Image,
  LinkBox,
  LinkOverlay,
  SkeletonCircle,
  SkeletonText,
  Text,
} from "@chakra-ui/react";
import React from "react";
import useRequest from "shared/src/hooks/useRequest";
import useProperty from "../components/useProperty";
import Post from "../interfaces/Post.interface";
import client from "admin/src/services/client";

const Content = ({ post }: { post: Post }) => {
  const items = post?.relatedEntities || [];
  const businessId = items.find((item) => item.type === "business")?.id;

  if (!post) return null;

  return (
    <>
      {items.map((item) => {
        if (item.type === "offer") {
          return (
            <OfferContentItem
              key={item.id}
              offerId={item.id}
              businessId={businessId}
            />
          );
        }
        return null;
      })}
    </>
  );
};

const OfferContentItem = ({
  offerId,
  businessId,
}: {
  offerId: string;
  businessId?: string;
}) => {
  const property = useProperty();

  if (!businessId) {
    // This is a weird case where we have an Offer but no Business
    // Should we assume this Offer is at _this_ Business???
    // Or just show nothing.
    return null;
  }

  const offerRequest = useRequest(
    async ({ offerId: id }: { offerId: string }) => {
      if (!id) return undefined;
      return client.properties
        .for(property.id)
        .businesses.for(businessId)
        .offers.for(offerId)
        .getIndexedOffer();
    },
    { offerId }
  );
  const OfferCard = () => {
    return (
      <>
        <Image
          boxSize="4em"
          borderRadius="full"
          src={offerRequest?.result?.heroImageUrl}
          flexShrink={0}
        />
        <Flex gap="0.5em" direction="column">
          <LinkOverlay href={`/business/${businessId}/offers/${offerId}`}>
            <Text fontWeight="bold">{offerRequest.result?.headline}</Text>
          </LinkOverlay>
          <Text>{offerRequest.result?.subHeadline}</Text>
        </Flex>
      </>
    );
  };

  return (
    <LinkBox
      as={Flex}
      align="center"
      gap="1em"
      borderTopWidth="1px"
      borderBottomWidth="1px"
      p="1em"
      w="100%"
      className="_cssTest"
      data-text="contentsection component"
    >
      {!offerRequest.settled && offerRequest.loading && <SkeletonOfferCard />}
      {!offerRequest.settled && !offerRequest.loading && (
        <Text fontSize="md" fontWeight="medium">
          No available offers
        </Text>
      )}
      {offerRequest.settled && <OfferCard />}
    </LinkBox>
  );
};

const SkeletonOfferCard = () => {
  return (
    <>
      <SkeletonCircle size="4em" flexShrink={0} />
      <SkeletonText noOfLines={2} w="100%" maxWidth="30ch" />
    </>
  );
};

export default Content;
