import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import businessesModule from '../../modules/businesses';
import client from '../../services/client';
import { updateElementTags } from '../../services/osm';
import AdminBusinessAddLocationView from './AdminBusinessAddLocationView';

class AdminBusinessAddLocationContainer extends Component {
  static propTypes = {
    business: PropTypes.object
  };

  state = {};

  close() {
    const { business } = this.props;
    this.setState({ redirectTo: `/businesses/${business.id}/locations` });
  }

  async handleSubmit(formData) {
    const { business } = this.props;

    const locationsRepo = client
      .businesses.for(business.id)
      .locations;

    const newLocation = await locationsRepo.create(formData);

    await locationsRepo.for(newLocation.id).waitForIndex();

    this.close();
  }

  async updateOsmAddress(address) {
    this.setState({ updatingAddress: true, updateError: null });

    const [elementType, elementId] = address.osmUid.split(' ');

    // Map our Address model to OSM tags
    const changes = {
      'addr:country': address.country,
      'addr:province': address.state,
      'addr:postcode': address.postalcode,
      'addr:city': address.city,
      'addr:street': address.street,
      'addr:housenumber': address.streetAddress,
      'addr:unit': address.suite
    };

    await updateElementTags(elementType, elementId, changes)
      .catch(err => this.setState({ updateError: err }));

    this.setState({ updatingAddress: false });
  }

  render() {
    if (this.state && this.state.redirectTo) return <Redirect to={this.state.redirectTo} />;

    const { business } = this.props;
    return (
      <AdminBusinessAddLocationView
        business={business}
        onSubmit={this.handleSubmit.bind(this)}
        onCancel={this.close.bind(this)}
        updateOsmAddress={this.updateOsmAddress.bind(this)}
        updatingAddress={this.state.updatingAddress}
      />
    );
  }
}

const mapState = state => ({
  business: businessesModule.selectors.business.getData(state)
});

export default connect(mapState)(AdminBusinessAddLocationContainer);
