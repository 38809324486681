export default {
  value: 'enterprise',
  label: 'Enterprise',
  itemPrices: [
    {
      label: 'Monthly',
      value: 'monthly',
      plan: {
        chargebeePlanId: 'enterprise-v2-local-monthly-cad',
        currencyCode: 'CAD'
      }
    },
    {
      label: 'Annually',
      value: 'annual',
      plan: {
        chargebeePlanId: 'enterprise-v2-local-annual-cad',
        currencyCode: 'CAD'
      }
    }
  ]
};
