import { DeleteIcon } from '@chakra-ui/icons';
import Post from 'business-dashboard/src/components/businesses/social/interfaces/Post.interface';
import React from 'react';
import {
  Badge,
  Flex,
  IconButton,
  LinkBox,
  Text,
} from '@chakra-ui/react';
import Campaign from 'business-dashboard/src/components/businesses/social/interfaces/Campaign.interface';
import usePagedList from 'shared/src/hooks/usePagedList';
import useRequest from 'shared/src/hooks/useRequest';
import useIncrement from 'shared/src/hooks/useIncrement';
import {
  Empty,
  TableCell,
  TableList,
  TableRow,
} from 'web-react-ui/src/chakra/TableList/TableList';
import Business from 'web-react-ui/src/types/Business.interface';
import client from '../../../../services/client';

const fetchCampaigns = async (
  { businessId }:
    { businessId: string },
): Promise<{ items: Array<Campaign> } | undefined> => {
  if (!businessId) return undefined;
  return client
    .businesses.for(businessId)
    .social.campaigns.list();
};

const campaignColumns = [
  {
    key: 'content',
    label: 'Content',
    cell: {
      w: '100%',
    },
    skeletonText: {
      noOfLines: 2,
      w: '100%',
    },
  },
  {
    key: 'content',
    label: 'Content',
    cell: {
      minW: '12rem',
    },
    skeletonText: {
      noOfLines: 1,
    },
  },
  {
    key: 'enabled',
    label: 'Enabled',
    cell: {
      minW: '12rem',
    },
    skeletonText: {
      noOfLines: 1,
    },
  },
  {
    key: 'actions',
    label: 'Actions',
    cell: {
      minW: '8rem',
      textAlign: 'right',
    },
    skeletonText: {
      noOfLines: 1,
    },
  },
];

const SocialCampaignsList = ({ business }: { business: Business }) => {
  const [key, invalidate] = useIncrement();

  const campaignList = usePagedList(
    fetchCampaigns,
    {
      propertyId: process.env.REACT_APP_DEFAULT_PROPERTY,
      businessId: business.id,
      key,
    },
  );

  return (
    <TableList columns={campaignColumns} isLoading={campaignList.loading}>
      <Empty isEmpty={campaignList.empty}>
        <Text fontSize="xl">No Campaigns To Display</Text>
      </Empty>
      {campaignList.items.map((campaign: Post) => (
        <CampaignItem key={campaign.id} campaign={campaign} business={business} onDelete={invalidate} />
      ))}
    </TableList>
  );
};

const CampaignItem = (
  { business, campaign, onDelete }:
    { business: Business, campaign: Campaign, onDelete: () => void },
) => {
  const deleteCampaignRequest = useRequest(
    () => {
      // eslint-disable-next-line no-restricted-globals
      if (!confirm(`Are you sure you want to delete the ${campaign.type} Campaign?`)) {
        return null;
      }

      return client
        .businesses.for(business.id)
        .social.campaigns.for(campaign.type)
        .delete()
        .then(onDelete);
    },
  );

  return (
    <LinkBox as={TableRow}>
      <TableCell column={campaignColumns[0]}>
        <Flex gap="1em" align="center">
          <Text>{campaign.name}</Text>
        </Flex>
      </TableCell>
      <TableCell column={campaignColumns[1]}>
        <Flex gap="1em" align="center">
          <Badge>{campaign.contentType === 'story' ? 'STORY' : 'POST'}</Badge>
        </Flex>
      </TableCell>
      <TableCell column={campaignColumns[2]}>
        <Flex gap="1em" align="center">
          <Badge>{campaign.enabled ? 'ENABLED' : 'DISABLED'}</Badge>
        </Flex>
      </TableCell>
      <TableCell column={campaignColumns[3]}>
        <IconButton
          aria-label="Delete Campaign"
          icon={<DeleteIcon />}
          onClick={deleteCampaignRequest.run}
          isLoading={deleteCampaignRequest.loading}
        />
      </TableCell>
    </LinkBox>
  );
};

export default SocialCampaignsList;
