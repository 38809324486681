/* eslint class-methods-use-this: 0, jsx-a11y/accessible-emoji: 0 */
import PropTypes from 'prop-types';
import React, {
  Component,
  Fragment
} from 'react';
import Message from 'web-react-ui/src/components/collections/Message';
import Button from 'web-react-ui/src/components/elements/Button';
import Label from 'web-react-ui/src/components/elements/Label';
import View from 'web-react-ui/src/components/layout/View';
import SearchBase from 'web-react-ui/src/components/newSearchBase/SearchBase';
import WaitFor from 'web-react-ui/src/data/WaitFor';
import Map from 'web-react-ui/src/map/Map';
import client from '../../services/client';


const searchAddresses = query => client.addresses.search({ query })
  .then(pList => pList.items);

const detailFields = [
  'neighbourhood',
  'city',
  'state',
  'county',
  'country',
  'locality',
  'centre'
];

const PersistButton = ({ address, onClick }) => {
  const label = address.id ? 'Persisted' : 'Persist';

  return (
    <Fragment>
      {!address.id && (
        <Button onClick={onClick} disabled={!address.isComplete} primary>
          {label}
        </Button>
      )}

      {address.id && (
        <Label color='green'>
          ✔&nbsp;Persisted
        </Label>
      )}
    </Fragment>
  );
};
PersistButton.propTypes = {
  address: PropTypes.object,
  onClick: PropTypes.func
};

const IngestButton = ({ address, onClick }) => {
  const canIngest = address.id && address.token;
  return (
    <Button onClick={onClick} disabled={!canIngest}>
      Update&nbsp;from&nbsp;OSM
    </Button>
  );
};
IngestButton.propTypes = {
  address: PropTypes.object,
  onClick: PropTypes.func
};

class AdminAddresses extends Component {
  state = {};

  handleSearchChange(address) {
    this.setState({ address });
  }

  handlePersist() {
    const { address } = this.state;
    this.setState({ isPersisting: true, error: null });
    client.addresses.persist(address)
      .then(
        ({ id }) => this.setState(({ address: r }) => {
          // Set id for view because persist response has very few fields
          r.id = id; // eslint-disable-line no-param-reassign
          return r;
        }),
        error => this.setState({ error })
      )
      .then(() => {
        this.setState({ isPersisting: false });
      });
  }

  async handleIngest() {
    const { address } = this.state;
    this.setState({ isIngesting: true });
    await client.addresses.sync(address.id, address.token);
    this.setState({ isIngesting: false });
  }

  render() {
    const { address, isPersisting, isIngesting } = this.state;
    const center = address && [address.centre.lat, address.centre.long];
    const details = address && detailFields.map(field => ({ name: field, value: address[field] }))
      .filter(({ value }) => value != null);

    return (
      <View>
        <WaitFor waitFor={!(isPersisting || isIngesting)} wrapContents>
          <View.Section>
            <h1>Addresses</h1>

            <label>
              Find address...
              <SearchBase
                getLabel={i => (i ? `${i.id ? '✔ ' : ''}${i.label}` : '')}
                search={searchAddresses}
                idField='osmUid'
                onChange={this.handleSearchChange.bind(this)}
              />
            </label>
          </View.Section>
          {address && (
            <View.Section>
              <div className='flex'>
                <h2 className='fg1'>{address.label}</h2>
                <div className='flex mla aifs'>
                  <PersistButton address={address} onClick={this.handlePersist.bind(this)}/>
                  <IngestButton address={address} onClick={this.handleIngest.bind(this)}/>
                </div>
              </div>

              {!address || address.isComplete
                ? null
                : (
                  <Message info>
                    <span>This Address is missing some information we need. </span>
                    <a href={address.osmEditUrl} target="_blank" rel="noopener noreferrer">Edit in OSM</a>
                  </Message>
                )
              }

              <div className='flex'>

                <div style={{ width: '50%' }}>
                  <dl>
                    {details && details.map(({ name, value }) => (
                      <Fragment key={name}>
                        <dt>{name}</dt>
                        <dd>{JSON.stringify(value)}</dd>
                      </Fragment>
                    ))}
                  </dl>
                </div>
                <Map
                  key={address.osmUid}
                  markers={center && [{ center }]}
                  initialCenter={center}
                />
              </div>
            </View.Section>

          )}
        </WaitFor>
      </View>
    );
  }
}

export default AdminAddresses;
