import {
  Text,
  Button,
  VStack,
  Heading,
  Spinner,
  useToast
} from '@chakra-ui/react';
import {
  useQuerystringParam,
  transport
} from 'shared/src/hooks/useQuerystringParam';


import useRequest from 'shared/src/hooks/useRequest';
import useChargebee from 'shared/src/hooks/chargebee/useChargebee';

import React, {
  useEffect,
  useMemo,
  useState
} from 'react';
import {
  useHistory,
  useParams
} from 'react-router-dom';
import useClient from 'shared/src/hooks/useClient';
import ErrorAlert from 'web-react-ui/src/chakra/ErrorAlert';
import View from 'web-react-ui/src/components/layout/View';

const SCREENS = {
  choice: 'choice',
  manual: 'manual',
  paymentDetails: 'paymentDetails',
  paymentLink: 'paymentLink'
};

const PostCreateBusinessView = () => {
  const [screen, setScreen] = useState(SCREENS.choice);
  const onComplete = () => setScreen(SCREENS.manual);

  return (
    <View>
      <View.Section narrow>
        <VStack>
          {screen === SCREENS.choice && (
            <ChoiceScreen onChoice={setScreen} />
          )}
          {screen === SCREENS.manual && (
            <CompleteScreen />
          )}
          {screen === SCREENS.paymentLink && (
            <PaymentLinkScreen onComplete={onComplete} />
          )}
          {screen === SCREENS.paymentDetails && (
            <PaymentDetailsScreen onComplete={onComplete} />
          )}
        </VStack>
      </View.Section>
    </View>
  );
};


const openPaymentPortalSession = ({ client, businessId, cbInstance, onPaymentAdded }) => {
  const propertyId = process.env.REACT_APP_DEFAULT_PROPERTY;
  // eslint-disable-next-line no-undef
  const sectionTypes = window.Chargebee.getPortalSections();
  const cbPortal = cbInstance.createChargebeePortal();
  cbInstance.setPortalSession(
    () => client.properties.for(propertyId).businesses.for(businessId).getPortalSession()
  );
  cbPortal.open(
    {
      close: () => {
        cbInstance.logout();
      },
      paymentSourceAdd: () => {
        cbInstance.logout();
        cbInstance.closeAll();
        onPaymentAdded?.();
      }
    },
    { sectionType: sectionTypes.ADD_PAYMENT_SOURCE }
  );
};

const createSubscription = async ({ client, businessId, subscription }) => {
  const propertyId = process.env.REACT_APP_DEFAULT_PROPERTY;

  return client
    .properties.for(propertyId)
    .businesses.for(businessId)
    .createSubscription(subscription);
};

const ChoiceScreen = ({ onChoice }) => {
  const client = useClient();
  const { businessId } = useParams();
  const createSubscriptionRequest = useRequest(createSubscription);

  const [plan] = useQuerystringParam('plan');
  const [price] = useQuerystringParam('price', undefined, { transport: transport.float });
  const [onboardingFee] = useQuerystringParam('onboardingFee', undefined, { transport: transport.float });
  const [autoCollection] = useQuerystringParam('autoCollection');
  const [coupons] = useQuerystringParam('coupons', undefined, { transport: transport.array });
  let [freePeriod] = useQuerystringParam('freePeriod', undefined, { transport: transport.positiveNumber });
  let [freePeriodUnit] = useQuerystringParam('freePeriodUnit');

  // Both must be set and `freePeriod` must be positive
  if (!freePeriod || !freePeriodUnit) {
    freePeriod = undefined;
    freePeriodUnit = undefined;
  }

  const createSubscriptionForChoice = useMemo(
    () => {
      return (choice) => {
        const subscription = {
          planItemPriceId: plan,
          // We round because, for example, 4348.77*100 === 434877.00000000006
          planUnitPrice: price ? Math.round(price * 100) : price,
          onboardingUnitPrice: onboardingFee ? Math.round(onboardingFee * 100) : onboardingFee,
          autoCollection,
          freePeriod,
          freePeriodUnit,
          couponIds: coupons
        };
        if (choice === SCREENS.manual) {
          subscription.autoCollection = 'off';
        }
        createSubscriptionRequest.run({ client, businessId, subscription })
          .then(() => {
            onChoice(choice);
          });
      };
    },
    [client]
  );

  useEffect(
    () => {
      // If manual billing is explicit (eg: CDAP), we just create the subscription and we're done
      if (autoCollection === 'off') createSubscriptionForChoice(SCREENS.manual);
    },
    [autoCollection]
  );


  return (
    <>
      <Heading>Payment & Billing</Heading>
      {
        createSubscriptionRequest.loading
          ? (
            <Spinner />
          )
          : (
            <>
              <Text>How should this business be billed?</Text>
              <VStack align="stretch">
                <Button onClick={() => createSubscriptionForChoice(SCREENS.manual)}>
                  Set for Manual Invoicing
                </Button>
                <Button onClick={() => createSubscriptionForChoice(SCREENS.paymentDetails)}>
                  Enter Payment Details
                </Button>
                <Button onClick={() => createSubscriptionForChoice(SCREENS.paymentLink)}>
                  Generate Payment Link
                </Button>
              </VStack>
              <ErrorAlert error={createSubscriptionRequest.error} />
            </>
          )
      }
    </>
  );
};

const CompleteScreen = () => {
  const { businessId } = useParams();
  const history = useHistory();

  useEffect(
    () => {
      history.push(`/businesses/${businessId}`);
    },
    [businessId, history]
  );

  return (
    <>
      <Heading>Setup Complete!</Heading>
      <Text>Business is all ready to go.</Text>
    </>
  );
};


const PaymentLinkScreen = ({ onComplete }) => {
  const client = useClient();
  const propertyId = process.env.REACT_APP_DEFAULT_PROPERTY;
  const { businessId } = useParams();
  const toast = useToast();

  const paymentLinkRequest = useRequest(
    // TODO:  Change to new "Manage Payment Sources" Hosted Page api route
    () => client.properties.for(propertyId).businesses.for(businessId).createPaymentUrl(),
    [businessId]
  );

  const copyLink = useMemo(
    () => () => {
      // eslint-disable-next-line no-undef
      navigator.clipboard.writeText(paymentLinkRequest.result?.url);
      toast({
        description: 'Link copied to clipboard!',
        status: 'success'
      });
    },
    [paymentLinkRequest.result?.url]
  );

  return (
    <>
      <Heading>Setup Complete!</Heading>
      <Text>Send this link to the customer so they can enter their billing details:</Text>
      {
        paymentLinkRequest.loading
          ? (<Spinner />)
          : (
            <>
              <ErrorAlert error={paymentLinkRequest.error} />
              <Button variant="solid" colorScheme="blue" onClick={copyLink}>Copy Link</Button>
              <Button onClick={onComplete}>Go to Business</Button>
            </>
          )
      }
    </>
  );
};

const PaymentDetailsScreen = ({ onComplete }) => {
  const client = useClient();
  const { businessId } = useParams();
  const cbInstance = useChargebee();

  const openPaymentPortal = useMemo(
    () => {
      // eslint-disable-next-line consistent-return
      return () => {
        if (!cbInstance) return undefined;

        openPaymentPortalSession({
          client,
          businessId,
          cbInstance,
          onPaymentAdded: onComplete
        });
      };
    },
    [client, businessId, cbInstance]
  );

  // Run on mount
  useEffect(openPaymentPortal, [openPaymentPortal]);

  return (
    <>
      <Heading>Setup Complete!</Heading>
      <Button onClick={openPaymentPortal}>Enter Payment Details</Button>
    </>
  );
};

export default PostCreateBusinessView;
