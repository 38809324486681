import {
  cadBiaTourism,
  cadBiaTourismTenant
} from './cad/biaTourism';
import cadCdap5050 from './cad/cdap5050';
import essentialCad from './cad/localBusiness/essential';
import socialaiCad from './cad/localBusiness/socialai';
import loyaltyCad from './cad/localBusiness/loyalty';
import premiumCad from './cad/localBusiness/premium';
import enterpriseCad from './cad/localBusiness/enterprise';
import {
  cadShoppingCentre,
  cadShoppingCentreTenant
} from './cad/shoppingCentre';
import {
  usdBiaTourism,
  usdBiaTourismTenant
} from './usd/biaTourism';

import essentialUsd from './usd/localBusiness/essential';
import socialaiUsd from './usd/localBusiness/socialai';
import loyaltyUsd from './usd/localBusiness/loyalty';
import premiumUsd from './usd/localBusiness/premium';
import enterpriseUsd from './usd/localBusiness/enterprise';
import {
  usdShoppingCentre,
  usdShoppingCentreTenant
} from './usd/shoppingCentre';

const cadLocalBusinessCustomer = {
  value: 'localBusiness',
  label: 'Local Business',
  plans: [
    essentialCad,
    socialaiCad,
    loyaltyCad,
    premiumCad,
    enterpriseCad
  ]
};

const usdLocalBusinessCustomer = {
  value: 'localBusiness',
  label: 'Local Business',
  plans: [
    essentialUsd,
    socialaiUsd,
    loyaltyUsd,
    premiumUsd,
    enterpriseUsd
  ]
};


const planOptionsModel = {
  countries: [
    {
      label: 'Canada',
      value: 'CA',
      customers: [
        cadLocalBusinessCustomer,
        cadShoppingCentre,
        cadShoppingCentreTenant,
        cadBiaTourism,
        cadBiaTourismTenant,
        cadCdap5050
      ]
    },
    {
      label: 'United States',
      value: 'US',
      customers: [
        usdLocalBusinessCustomer,
        usdShoppingCentre,
        usdShoppingCentreTenant,
        usdBiaTourism,
        usdBiaTourismTenant
      ]
    }
  ]
};

export default planOptionsModel;
