/**
 * Column is used to generate the column headers and loading skeletons.
 * It is practical to use the column elements to define the layout of the TableRow cells as well.
 * eg:
 * const columns = [
 *   {
 *     key: 'name',
 *     label: 'Name',
 *     cell: {
 *       width: '100%'
 *     },
 *     skeletonText: {
 *       noOfLines: 2
 *     }
 *   }
 * ];
 *
 * <TableList columns={columns}>
 *   {items.map(item => (
 *     <TableRow>
 *       <Box {...columns[0].cell>
 *         ...
 *       </Box>
 *     </TableRow>
 *   ))}
 * </TableList>
 */

import {
  Flex,
  FlexProps,
  SkeletonProps,
  SkeletonTextProps,
  SkeletonCircleProps,
  SkeletonCircle,
  SkeletonText,
  StackDivider,
  Text,
  VStack,
  Box,
  Skeleton,
} from '@chakra-ui/react';
import React, { ReactNode } from 'react';

interface Column {
  // Displayed in column header
  label?: string;
  key: string | number;
  // Props spread to the table header cell and skeleton cell
  cell: FlexProps,
  // Props spread to the skeleton cell
  skeleton: SkeletonProps,
  skeletonText: SkeletonTextProps,
  skeletonCircle: SkeletonCircleProps
}

const TableList = ({ children, columns, isLoading }: {
  children: ReactNode,
  columns: Column[],
  isLoading: boolean
}) => {
  return (
    <VStack
      borderWidth="1px"
      borderRadius={8}
      align="stretch"
      divider={<StackDivider />}
      spacing={0}
    >
      <TableHeader columns={columns} />
      {
        isLoading
          ? (
            <>
              <RowSkeleton key={1} columns={columns} />
              <RowSkeleton key={2} columns={columns} opacity={0.5} />
              <RowSkeleton key={3} columns={columns} opacity={0.25} />
              <RowSkeleton key={4} columns={columns} opacity={0.125} />
            </>
          )
          : children
      }
    </VStack>
  );
};

const TableHeader = ({ columns }: { columns: Column[] }) => {
  return (
    <Flex
      gap="1em"
      px="1em"
      py="0.5em"
      bg="gray.200"
      fontWeight={600}
      fontSize="xl"
    >
      {columns?.map(
        (column) => (
          <Box visibility={{ base: 'hidden', sm: 'visible' }} key={column.key} {...column.cell}>
            <Text fontWeight="bold" fontSize="lg">{column.label}</Text>
          </Box>
        ),
      )}
    </Flex>
  );
};

const TableRow = ({ children, ...rest }: { children: ReactNode }) => {
  return (
    <Flex
      gap="1em"
      p="1em"
      alignItems="center"
      justifyContent="space-between"
      cursor="pointer"
      _hover={{ bg: 'gray.50' }}
      sx={{
        '&:last-child': {
          borderBottomRadius: 8,
        },
      }}
      wrap={{ base: 'wrap', sm: 'nowrap' }}
      {...rest}
    >
      {children}
    </Flex>
  );
};

const TableCell = ({ children, column, ...rest }: { children: ReactNode, column: Column }) => {
  return (
    <Box {...column?.cell} {...rest}>
      {children}
    </Box>
  );
};

const RowSkeleton = ({ columns, ...rest }: { columns: Column[] }) => {
  return (
    <TableRow position="relative" align="stretch" {...rest}>
      {columns.map((column) => {
        const cellProps = column.cell;
        const skeletonProps = column.skeleton;
        const skeletonTextProps = column.skeletonText;
        const skeletonCircleProps = column.skeletonCircle;

        const children = [];

        if (skeletonProps) {
          children.push(<Skeleton flexGrow={1} {...skeletonProps} />);
        }

        if (skeletonCircleProps) {
          children.push(<SkeletonCircle flexShrink={0} {...skeletonCircleProps} />);
        }

        if (skeletonTextProps) {
          children.push(<SkeletonText flexGrow={1} {...skeletonTextProps} />);
        }

        return (
          <Flex align="center" gap="1em" {...cellProps}>
            {children}
          </Flex>
        );
      })}
    </TableRow>
  );
};

const Empty = ({ isEmpty, children }: { isEmpty: boolean, children: ReactNode }) => {
  if (!isEmpty) return null;

  return (
    <Flex
      align="center"
      color="gray.400"
      direction="column"
      gap="2em"
      justify="center"
      minH="100px"
      p="2em"
      textAlign="center"
    >
      {children}
    </Flex>
  );
};

export {
  TableList,
  TableRow,
  TableCell,
  Empty,
};
