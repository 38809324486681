/* eslint-disable no-shadow */
// vvv DO NOT REMOVE vvv -- prevents CRA's fast refresh from spectacularly failing to refresh this component
// @refresh reset
// ^^^ DO NOT REMOVE ^^^
import AddAddress from 'web-react-ui/src/components/businessSignUp/AddAddress';
import AddBusinessDetails from 'web-react-ui/src/components/businessSignUp/AddBusinessDetails';
import AddImages from 'web-react-ui/src/components/businessSignUp/AddImages';
import AddPlanStep from 'web-react-ui/src/components/businessSignUp/AddPlanStep';

import ConfirmMapLocationStep from 'web-react-ui/src/components/businessSignUp/ConfirmMapLocationStep';
import { WizardManager } from 'web-react-ui/src/components/wizard/WizardManager';
import { strings } from '../../../../i18n';
import client from '../../../../services/client';
import confirm from '../../../../services/confirm';
import ChoosePlanStep from './ChoosePlanStep';
import ConfirmDetailsStep from './ConfirmDetailsStep';
// Questionable: We should not just duplicate this here
import CreateBusinessPipeline from '../CreateBusinessPipeline';

const publishBusiness = async (wizardData, manager) => {
  const {
    address: addressFields,
    business: businessFields,
    property,
    user,
    paymentPlan,
    hubspot
  } = wizardData;
  if (!manager.pipeline) throw new Error('No save pipeline found');

  const results = await manager.pipeline.run({
    addressFields,
    businessFields,
    property,
    user,
    paymentPlan,
    hubspot
  });

  return results;
};

const wrapOnCancel = onCancel => (value) => {
  /* eslint-disable-next-line max-len */
  if (!confirm(strings('dashboard.component.createBusinessWizard.confirm'))) return false;
  if (onCancel) return onCancel(value);
  return value;
};

const getWizard = ({
  property,
  business,
  address,
  user,
  paymentPlan,
  locale,
  onComplete,
  onCancel
}) => {
  const steps = [
    AddBusinessDetails(),
    AddAddress(),
    ConfirmMapLocationStep(),
    AddImages(),
    ...(
      business.token
        ? [] // Skip choosing a plan if we created the Business from a token
        : [ChoosePlanStep()]
    ),
    ConfirmDetailsStep()
  ];

  return new WizardManager({
    initialData: {
      property,
      business,
      address,
      user,
      paymentPlan,
      locale
    },
    steps,
    onStart: (manager) => {
      // Create a new saving pipeline manager each time the wizard is instantiated.
      // eslint-disable-next-line no-param-reassign
      manager.pipeline = new CreateBusinessPipeline(client);
    },
    onComplete: async (wizardData, manager) => {
      const paymentPlan = wizardData.paymentPlan;
      const { business: finalBusiness, location: finalLocation } = await publishBusiness(wizardData, manager);
      if (onComplete) onComplete(finalBusiness, paymentPlan, finalLocation);
    },
    onCancel: onCancel ? wrapOnCancel(onCancel) : null,
  });
};

export default getWizard;
