import React from 'react';
import {
  Button,
  Heading,
} from '@chakra-ui/react';
import {
  SOCIAL_PROFILE_FIELDS,
  formatSocial,
  parseSocial,
} from 'shared/src/constants/socialProfiles';
import { FieldRenderProps } from 'react-final-form';
import FieldListField from 'web-react-ui/src/reactFinalForm/fields/FieldListField';

import Location from 'web-react-ui/src/types/Location.interface';
import BigModal from 'web-react-ui/src/BigModal/BigModal';
import Menu from 'web-react-ui/src/components/collections/Menu';
import Message from 'web-react-ui/src/components/collections/Message';
import CancelButton from 'web-react-ui/src/components/elements/CancelButton';
import Divider from 'web-react-ui/src/components/elements/Divider';
import View from 'web-react-ui/src/components/layout/View';
import WaitFor from 'web-react-ui/src/data/WaitFor';
import Map from 'web-react-ui/src/map/Map';
import { Field } from 'web-react-ui/src/reactFinalForm';
import {
  FieldError,
  TextField,
} from 'web-react-ui/src/reactFinalForm/fields';
import FieldLabel from 'web-react-ui/src/reactFinalForm/fields/FieldLabel';
import LocaleSelector from 'web-react-ui/src/reactFinalForm/fields/localizableField/LocaleSelector';
import LocalizableSimpleForm from 'web-react-ui/src/reactFinalForm/fields/localizableField/LocalizableSimpleForm';
import LocalizableTextField from 'web-react-ui/src/reactFinalForm/fields/localizableField/LocalizableTextField';
import {
  atLeastOneCompleteLocalization,
  forAllLocales,
  max,
  required,
  slug,
} from 'web-react-ui/src/reactFinalForm/validators';

import AddressOsmIdSearch from '../AddressOsmIdSearch';
import AddressSearch from '../AddressSearch';
import PlatformTranslationWrapper from '../i18n/PlatformTranslationWrapper';
import ExternalIdFields from './ExternalIdFields';

const EditLocationModal = ({
  location,
  externalIds,
  onSubmit,
  onCancel,
  open,
  loading,
  error,
}: {
  location: Location | null,
  externalIds: { key: string, value: string }[],
  onSubmit: (fields: { [key: string]: any; }) => any,
  onCancel: () => void,
  open: boolean,
  loading: boolean,
  error?: string,
}) => {
  return (
    <BigModal open={open}>
      <PlatformTranslationWrapper>
        <LocalizableSimpleForm
          onSubmit={onSubmit}
          initialValues={{ ...location, externalIds }}
          validateLocale={atLeastOneCompleteLocalization(['nameLocalized'])}
        >
          <BigModal.Contents>
            <BigModal.Header className="p1">
              <Menu secondary>
                <Menu.Item fitted>
                  <CancelButton type="button" onClick={onCancel} />
                </Menu.Item>
                <Menu.Menu position="right">
                  <Menu.Item fitted>
                    <Button type="submit">Save</Button>
                  </Menu.Item>
                </Menu.Menu>
              </Menu>
            </BigModal.Header>
            <BigModal.Body>
              <View>
                <WaitFor waitFor={!loading}>

                  <View.Section narrow>
                    {error && (
                      <Message error>
                        {error}
                      </Message>
                    )}
                    <div className="flex mb1">
                      <h2>Edit Location</h2>
                      <LocaleSelector inline className="mla" />
                    </div>
                    <TextField label="Slug" name="slug" placeholder="Slug" validate={slug} />
                    <LocalizableTextField
                      label="Name"
                      name="nameLocalized"
                      placeholder="Name"
                      validateLocale={forAllLocales(max(64))}
                    />
                    <TextField name="url" label="Url" type="url" validate={max(512)} parse={(v: string) => v} />
                    <TextField name="phone" label="Phone Number" type="tel" validate={max(35)} />
                    <div className="field">
                      <Field name="address" validate={required}>
                        {({ input }: FieldRenderProps<any>) => (
                          <label>
                            Find Address
                            <AddressSearch placeholder="Search for address..." {...input} />
                            <FieldError name="address" />
                            {!input.value.isComplete && (
                              <Message error size="mini">
                                Location incomplete
                              </Message>
                            )}
                          </label>
                        )}
                      </Field>

                      <Divider horizontal>OR</Divider>

                      <Field name="address">
                        {({ input }: FieldRenderProps<any>) => (
                          <FieldLabel label="OSM URL" wrapLabel>
                            <AddressOsmIdSearch
                              {...input}
                              placeholder="https://www.openstreetmap.org/node/6910530351"
                            />
                            <FieldError name="address" />
                          </FieldLabel>
                        )}
                      </Field>
                    </div>

                  </View.Section>

                  <View.Section narrow divided>
                    <h5>Address</h5>
                    <Field name="address">
                      {({ input: { value: address } }: FieldRenderProps<any>) => (
                        <React.Fragment>
                          <p>{address.label}</p>
                          {address && (
                            <Map
                              initialCenter={[address.centre.lat, address.centre.long]}
                              initialZoom={15}
                              markers={[{ center: [address.centre.lat, address.centre.long] }]}
                            />
                          )}
                        </React.Fragment>
                      )}
                    </Field>
                  </View.Section>

                  <View.Section narrow divided>
                    <ExternalIdFields />
                  </View.Section>

                  <View.Section narrow>
                    <Heading size="md" mb={2}>Social Media Profiles</Heading>
                    <FieldListField
                      name="socialProfiles"
                      addButtonLabel="Add"
                      fields={SOCIAL_PROFILE_FIELDS}
                      parse={parseSocial}
                      format={formatSocial}
                    />
                  </View.Section>
                </WaitFor>
              </View>
            </BigModal.Body>
          </BigModal.Contents>
        </LocalizableSimpleForm>
      </PlatformTranslationWrapper>
    </BigModal>
  );
};

export default EditLocationModal;
