import CancelablePromise from 'shared/src/modules/promise/CancelablePromise';
import { types } from './types';
import { loadImage } from '../../components/LoadImages';

const fetchProperty = async (client, id) => {
  const propertyRepo = client.properties.for(id);

  const [property, locales, settings] = await Promise.all([
    propertyRepo.details().then(res => loadImage(res, 'profileImage')),
    propertyRepo.getLocales().then(res => res.locales),
    propertyRepo.getSettings().then(res => res.items)
  ]);

  property.locales = locales;
  property.settings = settings.reduce(
    (acc, val) => {
      acc[val.key] = val.value;
      return acc;
    },
    {}
  );

  return property;
};

let pending;
const propertyMiddleware = client => store => next => async (action) => {
  const { type, id } = action;
  switch (type) {
    case types.PROPERTY_DETAILS_REQUEST:
      // already loading for this action, ignore request
      if (pending?.action.id === action.id) {
        return null;
      }

      // pending promise, not yet settled, cancel it
      if (pending && !pending.isSettled) {
        pending.cancel();
      }

      pending = new CancelablePromise(fetchProperty(client, id), action);

      return pending.promise.then((entity) => {
        store.dispatch({
          type: types.PROPERTY_DETAILS_SUCCESS,
          payload: {
            data: entity
          }
        });
        return entity;
      }).catch((error) => {
        if (error.message === CancelablePromise.CANCELED) return null;

        if (pending.isCanceled === action.id) return null;

        store.dispatch({
          type: types.PROPERTY_DETAILS_FAILURE,
          error,
        });
        return error;
      });
    default:
      return next(action);
  }
};

export default propertyMiddleware;
