import {
  Text,
  Skeleton,
  chakra,
  FormLabel,
  FormControl,
  Textarea,
  Flex,
} from '@chakra-ui/react';
import React, {
  useMemo,
} from 'react';
import ErrorAlert from 'web-react-ui/src/chakra/ErrorAlert';
import PageHeader from 'web-react-ui/src/chakra/page/PageHeader';
import PageLayout from 'web-react-ui/src/chakra/page/PageLayout';
import ActionMenu from 'web-react-ui/src/components/actionMenu/ActionMenu';
import View from 'web-react-ui/src/components/layout/View';
import Business from 'web-react-ui/src/types/Business.interface';
import useRequest from 'shared/src/hooks/useRequest';
import client from '../../../services/client';

const fetchBusinessSummary = async (businessId: string) => {
  const businessRepo = client.businesses.for(businessId);

  const businessMeta = await businessRepo
    .coreDetails(undefined, { fields: ['meta'] });

  if (!businessMeta?.meta?.summarizeTaskId) return null;

  return businessRepo
    .awaitSummarizeTask(businessMeta.meta.summarizeTaskId);
};

const crawlBusiness = async (businessId: string) => {
  const businessRepo = client.businesses.for(businessId);

  const crawlTask = await businessRepo
    .runCrawlTask(businessId);

  await businessRepo
    .awaitCrawlTask(crawlTask.id);

  return crawlTask; // We just need the task id
};

const summarizeBusiness = async (businessId: string, crawlId: string) => {
  const businessRepo = client.businesses.for(businessId);

  const summarizeTask = await businessRepo
    .runSummarizeTask(crawlId);

  return businessRepo
    .awaitSummarizeTask(summarizeTask.id);
};

const PromoAi = ({ business }: { business: Business }) => {
  const summaryRequest = useRequest(
    async () => {
      if (!business.id) return null;

      return fetchBusinessSummary(business.id);
    },
    [business.id],
  );

  const generateSummaryRequest = useRequest(
    async ({ businessId }: { businessId: string }) => {
      if (!businessId) return null;

      const crawlTask = await crawlBusiness(businessId);

      await summarizeBusiness(businessId, crawlTask.id);

      return summaryRequest.run();
    },
  );

  const loading = summaryRequest.loading || generateSummaryRequest.loading;
  const error = summaryRequest.error || generateSummaryRequest.error;

  const actions = useMemo(
    () => ([
      {
        label: 'Regenerate',
        action: () => generateSummaryRequest.run({ businessId: business.id }),
        extraProps: {
          isLoading: loading,
        },
      },
    ]),
    [business, loading],
  );

  return (
    <View>
      <View.Section>
        <PageLayout>
          <PageHeader controls={<ActionMenu actions={actions} />}>
            Generated Business Context Data
          </PageHeader>

          <Text>
            Below is context data about the business generated automatically from the website and other data sources.
            <br />
            It is used by Promotions AI to suggest promotions for the business.
          </Text>

          <ErrorAlert error={error} />

          <chakra.form onSubmit={e => e.preventDefault()}>
            <Flex direction="column" gap="1em">
              <FormControl>
                <FormLabel>Detailed Business Summary</FormLabel>
                <Skeleton isLoaded={!loading}>
                  <Textarea readOnly value={summaryRequest.result?.result?.summary} />
                </Skeleton>
              </FormControl>
              <FormControl>
                <FormLabel>Products & Services</FormLabel>
                <Skeleton isLoaded={!loading}>
                  <Textarea readOnly value={summaryRequest.result?.result?.productsAndServices} />
                </Skeleton>
              </FormControl>
              <FormControl>
                <FormLabel>Business Metadata</FormLabel>
                <Skeleton isLoaded={!loading}>
                  <Textarea readOnly value={summaryRequest.result?.result?.metaData} />
                </Skeleton>
              </FormControl>
            </Flex>
          </chakra.form>
        </PageLayout>
      </View.Section>
    </View>
  );
};

export default PromoAi;
