/* eslint-disable-next-line no-redeclare */
/* global document */
import { ChakraProvider } from '@chakra-ui/react';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import UiClientContext from 'shared/src/components/contexts/ClientContext';

import I18NextContext from 'shared/src/components/contexts/I18NextContext';
import chakraTheme from 'web-react-ui/src/chakra-theme';

import 'web-react-ui/src/css';

import App from './App';
import ClientContext from './components/contexts/ClientContext';
import RollbarLogger from './components/RollbarLogger';

import * as i18Next from './i18n';

import './index.css';
import registerServiceWorker from './registerServiceWorker';
import client from './services/client';
import store from './store/store';

ReactDOM.render(
  <RollbarLogger>
    <ReduxProvider store={store}>
      <ClientContext.Provider value={client}>
        <UiClientContext.Provider value={client}>
          <I18NextContext.Provider value={i18Next}>
            <ChakraProvider theme={chakraTheme}>
              <App />
            </ChakraProvider>
          </I18NextContext.Provider>
        </UiClientContext.Provider>
      </ClientContext.Provider>
    </ReduxProvider>
  </RollbarLogger>,
  document.getElementById('root')
);

registerServiceWorker();
