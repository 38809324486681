/* eslint class-methods-use-this:0 */
import React, { Component } from 'react';

class Terms extends Component {
  render() {
    // TODO: This is an internal tool. No need for terms/conditions?
    const terms = 'https://getintheloop.com/business/terms';
    const privacy = 'https://getintheloop.com/business/privacy';
    const date = new Date().getFullYear();
    return (
      <div>
        <p className='mb0'>
          © {date} GetintheLoop Marketing, Ltd, All Rights Reserved.<br />
          <a className='pl1' target="_blank" rel="noopener noreferrer" href={ terms }>
            Terms of Service
          </a>
          &nbsp; and &nbsp;
          <a className='pr1' target="_blank" rel="noopener noreferrer" href={ privacy }>
            Privacy Policy
          </a>
        </p>
      </div>
    );
  }
}

export default Terms;
