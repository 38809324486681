const usdBiaTourism = {
  value: 'biaTourism',
  label: 'BIA/Tourism Group',
  plans: [
    {
      value: 'enterprise',
      label: 'Enterprise',
      itemPrices: [
        {
          value: 'monthly',
          label: 'Monthly',
          plan: {
            chargebeePlanId: 'enterprise-v2-mall-monthly-usd',
            currencyCode: 'USD'
          }
        },
        {
          value: 'annual',
          label: 'Annually',
          plan: {
            chargebeePlanId: 'enterprise-v2-mall-annual-usd',
            currencyCode: 'USD'
          }
        }
      ]
    }
  ]
};

const usdBiaTourismTenant = {
  value: 'biaTourismCentreTenant',
  label: 'BIA/Tourism Tenant',
  plans: [
    {
      value: 'enterprise',
      label: 'BIA/Tourism Tenant (Enterprise)',
      plan: {
        chargebeePlanId: 'enterprise-tenant-mall-monthly-usd',
        currencyCode: 'USD',
        defaultPrice: 0
      }
    }
  ]
};

export {
  usdBiaTourism,
  usdBiaTourismTenant
};
