import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { useI18Next } from 'shared/src/components/contexts/I18NextContext';

import ActionMenu from '../actionMenu/ActionMenu';
import Button from '../elements/Button';
import Label from '../elements/Label';
import List from '../elements/List';

import UserListProfileImage from '../image/UserListProfileImage';

// FIXME: Remove onDelete and onActivate, do everything through actions

const UserItem = ({
  user, onDelete, onActivate, actions, actionContext
}) => {
  const { strings } = useI18Next();

  const url = _.get(user, 'profileImage.url');

  return (
    <List.Item>
      <div className='flex aic'>
        <div className='flex fww fg1 jcsb'>
          <div className='flex aic'>
            <UserListProfileImage style={{ flexShrink: '0' }} imageUrl={url} userName={user.email}/>
            <div className='ml1'>
              <List.Header>{user.email}</List.Header>
            </div>
          </div>
          <div className='flex ph1 fg0'>
            <div className='mt1'>
              {user.roles && user.roles.map((role) => {
                if (role.name === 'Business Owner') {
                  return <Label color='green' key={role.id}>{role.name}</Label>;
                }
                return <Label key={role.id}>{role.name}</Label>;
              })}
            </div>
          </div>
        </div>
        <ActionMenu suppressPrimary actions={actions} context={actionContext}/>
        <div className='ph1 fg0 flex aic'>
          {
            onActivate
            &&
            <Button
              onClick={onActivate}
              type="button"
              circular
              basic
              icon='power'
              title={strings('ui.component.userItem.activate')}
              className='text'
            />
          }

          {
            onDelete
            &&
            <Button
              onClick={onDelete}
              type="button"
              circular
              basic
              icon='x'
              title={strings('ui.component.userItem.remove')}
              className='text'
            />
          }
        </div>
      </div>
    </List.Item>
  );
};

UserItem.propTypes = {
  user: PropTypes.object,
  onActivate: PropTypes.func,
  onDelete: PropTypes.func,
  actions: PropTypes.array,
  actionContext: PropTypes.any
};

export default UserItem;
