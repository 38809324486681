/* eslint consistent-return: 0 */
import { Component } from "react";

const withAsyncState = ({ load, shouldLoad = () => true }) => (renderFn) => {
  class WrappedClass extends Component {
    state = {};
    render() {
      return renderFn(this.state, this.props);
    }
    componentDidMount() {
      this.loadData();
    }
    componentDidUpdate(prevProps) {
      this.loadData(prevProps);
    }
    loadData(prevProps = {}) {
      if (prevProps === this.props) return;
      if (!shouldLoad(this.props, this.state)) return;
      this.setState({ isPending: true });
      return load(this.props, this.state)
        .then((data) => {
          this.setState({ isPending: false, data });
        });
    }
  }
  return WrappedClass;
};

export default withAsyncState;
