import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Icon from 'web-react-ui/src/components/elements/Icon';
import Input from 'web-react-ui/src/components/elements/Input';
import List from 'web-react-ui/src/components/elements/List';
import EmptyList from 'web-react-ui/src/components/entities/EmptyList';
import ErrorMessage from 'web-react-ui/src/components/entities/ErrorMessage';
import ProfileImage from 'web-react-ui/src/components/image/ProfileImage';
import View from 'web-react-ui/src/components/layout/View';
import PagedList from 'web-react-ui/src/components/pagedList/PagedList';
import WaitFor from 'web-react-ui/src/data/WaitFor';

import client from '../../services/client';
import RelativeLink from '../routing/RelativeLink';

const BusinessItem = (props) => {
  const { business } = props;
  const imageId = _.get(business, 'profileImage.id');
  return (
    <List.Item as={Link} to={`/businesses/${business.id}`}>
      <List.Content floated="right">
        <Icon className="long arrow alternate right pt1" />
      </List.Content>
      <div className="flex">
        <div className="mr1">
          <ProfileImage imageId={imageId} name={business.name} />
        </div>
        <List.Content className="mt1">
          <List.Header>{business.name}</List.Header>
        </List.Content>
      </div>
    </List.Item>
  );
};
BusinessItem.propTypes = {
  business: PropTypes.object,
  property: PropTypes.object
};

const fetchBusinesses = ({ query }) => client.businesses.list({ query });

class AdminBusinessesListView extends Component<{}> {
  state = {
    query: ''
  };

  handleSearchChange({ target: { value } }) {
    this.setState({ query: value });
  }

  render() {
    const { query } = this.state;
    return (
      <View>
        <View.Section className="mt3">
          <div className="flex mb2 aic">
            <Input
              type="search"
              fluid
              onChange={this.handleSearchChange.bind(this)}
              icon="search"
              placeholder="Business Name"
              className="fg1 mr1"
            />
            <RelativeLink className="ui button" style={{ marginLeft: 'auto' }} to={'./add/check'}>
              Add Business
            </RelativeLink>
          </div>

          <PagedList fetchList={fetchBusinesses} params={{ query }}>
            {({
              items, loading, isEmpty, listError
            }) => (
              <WaitFor waitFor={!loading} wrapContents>
                <List celled relaxed verticalAlign="middle" selection>
                  {isEmpty && <EmptyList message="No businesses found." />}
                  <ErrorMessage error={listError} />
                  {items.map(business => <BusinessItem business={business} key={business.id} />)}
                </List>
              </WaitFor>
            )}
          </PagedList>
        </View.Section>
      </View>
    );
  }
}

export default AdminBusinessesListView;
