import { types } from './types';

export const list = propertyId => ({
  type: types.BUSINESS_LIST_REQUEST,
  propertyId
});

export const details = args => ({
  type: types.BUSINESS_DETAILS_REQUEST,
  args
});

export const invalidate = () => ({
  type: types.BUSINESS_INVALIDATE,
});

export const invalidateAndRefresh = args => ({
  type: types.BUSINESS_INVALIDATE_REFRESH,
  args
});
