export default {
  value: 'socialai',
  label: 'Social AI',
  itemPrices: [
    {
      value: 'monthly',
      label: 'Monthly',
      plan: {
        chargebeePlanId: 'social-ai-local-monthly-cad',
        currencyCode: 'CAD',
        defaultPrice: 99
      }
    },
    {
      value: 'annual',
      label: 'Annually',
      plan: {
        chargebeePlanId: 'social-ai-local-annual-cad',
        currencyCode: 'CAD',
        defaultPrice: 990
      }
    }
  ]
};
