import React from 'react';
import { useQuerystringParam } from 'shared/src/hooks/useQuerystringParam';
import BigModal from 'web-react-ui/src/BigModal/BigModal';
import PageBackButton from 'web-react-ui/src/chakra/page/PageBackButton';
import { Box } from '@chakra-ui/react';
import useQuerystring from 'shared/src/hooks/useQuerystring';
import SocialPostDetails from './SocialPostDetails';

const SocialDetailsModal = () => {
  const [postId, setPostId] = useQuerystringParam('postId', '', {
    squash: true,
  });
  const [businessId, setBusinessId] = useQuerystringParam('businessId', '', {
    squash: true,
  });
  const [queryString] = useQuerystring();
  const qsp = new URLSearchParams(queryString);
  qsp.delete('businessId');
  qsp.delete('postId');
  const target = `/social?${qsp.toString()}`;
  const isOpen: boolean = postId && businessId;

  const onClose = () => {
    setPostId('');
    setBusinessId('');
  };

  return (
    <BigModal open={isOpen} onClose={onClose} py="8">
      <BigModal.Contents>
        <BigModal.Header className="p1" p="8">
          <PageBackButton to={target} fontSize="16">
            Back
          </PageBackButton>
        </BigModal.Header>
        <BigModal.Body>
          <Box
            w="100%"
            className="_modalBox"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <SocialPostDetails businessId={businessId} postId={postId} />
          </Box>
        </BigModal.Body>
      </BigModal.Contents>
    </BigModal>
  );
};

export default SocialDetailsModal;
