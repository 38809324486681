import {
  InputGroup,
  InputLeftAddon,
  NumberInput,
  NumberInputField
} from '@chakra-ui/react';
import React from 'react';
import { Field } from 'react-final-form';
import FieldError from 'web-react-ui/src/reactFinalForm/fields/FieldError';
import FieldLabel from 'web-react-ui/src/reactFinalForm/fields/FieldLabel';

const PriceField = ({
  name,
  label,
  validate,
  format,
  parse,
  info,
  formatOnBlur,
  optional,
  wrapLabel,
  disabled,
  ...rest
}) => {
  return (
    <Field name={name}
           validate={validate}
           format={format}
           info={info}
           formatOnBlur={formatOnBlur}>
      {({ input }) => (
        <FieldLabel label={label} optional={optional} wrapLabel disabled={disabled}>
          <InputGroup w="100%">
            <InputLeftAddon>$</InputLeftAddon>
            <NumberInput precision={2} flex={1} {...input} {...rest} disabled={disabled}>
              <NumberInputField />
            </NumberInput>
          </InputGroup>
          <FieldError name={name} chakra />
        </FieldLabel>
      )}
    </Field>
  );
};

export default PriceField;
