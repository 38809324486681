import {
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay
} from '@chakra-ui/react';
import React from 'react';
import { useI18Next } from 'shared/src/components/contexts/I18NextContext';
import PostCreateBusinessView from './PostCreateBusinessView';

const PostCreateBusinessContainer = () => {
  const { strings } = useI18Next();

  /* NOTE: Modal's trapFocus must be `false` for focus to work in Chargebee widget */
  return (
    <Modal isOpen={true} size="full" trapFocus={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader position="sticky" top={0} bg="white" borderRadius={8} zIndex={1}>
          <Heading fontSize="3xl" textAlign="center">
            {`${strings('ui.component.wizardModal.adding')} ${strings('ui.component.wizardModal.aNewBusiness')}`}
          </Heading>
        </ModalHeader>
        <ModalBody>
          <PostCreateBusinessView />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default PostCreateBusinessContainer;
