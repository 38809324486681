export default {
  value: 'essential',
  label: 'Starter',
  itemPrices: [
    {
      value: 'monthly',
      label: 'Monthly',
      plan: {
        chargebeePlanId: 'essential-local-monthly-usd',
        currencyCode: 'USD',
        defaultPrice: 0
      }
    }
  ]
};
