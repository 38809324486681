import { PlusSquareIcon } from '@chakra-ui/icons';
import {
  chakra,
  Box,
  Flex,
  Heading,
  SkeletonText,
  Thead,
  Table,
  Text,
  Tr,
  Td,
  Tbody,
  Spacer,
  Button,
  Link,
  TagLabel,
  Tag,
  TagCloseButton,
} from '@chakra-ui/react';
import React, {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import useRequest from 'shared/src/hooks/useRequest';
import { useQuerystringParam } from 'shared/src/hooks/useQuerystringParam';
import ErrorAlert from 'web-react-ui/src/chakra/ErrorAlert';
import { getTestRun } from './vellumService';
import {
  WorkflowExecution,
  WorkflowRun,
} from './WorkflowRun.interface';

const fetchExperiment = async ({ workspace, id }: { workspace: string, id: string }): Promise<WorkflowRun | null> => {
  if (!workspace || !id) return null;
  return getTestRun(workspace, id);
};

const CompareView = () => {
  const params = useParams<{ id: string }>();
  const [workspace] = useQuerystringParam('workspace', 'promotions-ai');

  const experimentRequest = useRequest(
    fetchExperiment,
    {
      workspace,
      id: params.id,
    },
  );

  return (
    <Flex
      direction="column"
      position="fixed"
      top={0}
      left={0}
      w="100%"
      h="100%"
      zIndex="1"
      background="white"
      overflow="auto"
    >
      <Heading m="1rem">Experiment Explorer</Heading>
      <Flex m="1rem">
        <Text fontSize="xl">{experimentRequest.result?.run?.test_suite?.label}</Text>
        <Spacer />
        <Text fontSize="xl">Evaluation Run: <a>{params.id}</a></Text>
      </Flex>
      {
        experimentRequest.settled
          ? (
            <WorkflowRunTable workflowRun={experimentRequest.result} />
          )
          : (
            <SkeletonText isLoaded={experimentRequest.settled} />
          )
      }
      <ErrorAlert error={experimentRequest.error} />
    </Flex>
  );
};

const HeaderCell = ({ name, hidden, onClick }: { name: string, hidden: boolean, onClick: () => void }) => {
  return (
    <Td onClick={onClick} title={hidden ? name : `Hide ${name}`} cursor="pointer">
      <Text textDecoration="underline">
        {hidden
          ? <PlusSquareIcon />
          : name
        }
      </Text>
    </Td>
  );
};

const DEFAULT_COLUMNS = ['businessName', 'businessCategory', 'businessDesc', 'businessServices', 'businessDetails'];

const WorkflowRunTable = ({ workflowRun }: { workflowRun: WorkflowRun }) => {
  const columns = workflowRun.testCases[0].input_values;

  const ref = useRef();

  const [hiddenCols, setHiddenCols] = useState(columns
    .map(c => c.name)
    .filter(v => !DEFAULT_COLUMNS.includes(v)),
  );

  const toggleHidden = useCallback(
    (name) => {
      setHiddenCols((hidden) => {
        if (hidden.includes(name)) return hidden.filter(i => i !== name);
        return [...hidden, name];
      });
    },
    [],
  );

  return (
    <Box>
      <Flex gap="1em" p="1rem">
        <Text>Hidden Columns</Text>
        {hiddenCols.map(name => (
          <Tag key={name} size="lg" borderRadius="full">
            <TagLabel>{name}</TagLabel>
            <TagCloseButton onClick={() => toggleHidden(name)} />
          </Tag>
        ))}
      </Flex>
      <Table
        size="sm"
        variant="striped"
        sx={{
          '& td': {
            borderLeft: '1px solid #ddd !important',
          },
        }}
        ref={ref}
      >
        <Thead position="sticky" top="0" background="white">
          <Tr>
            <Td>Row</Td>
            {columns.map((column) => {
              const name = column.name;
              return (
                <HeaderCell
                  key={name}
                  name={name}
                  hidden={hiddenCols.includes(name)}
                  onClick={() => toggleHidden(name)}
                />
              );
            })}
            <Td>Outputs</Td>
          </Tr>
        </Thead>
        <Tbody>
          {
            workflowRun.executions.map(
              (execution, index) => (
                <ExecutionRow
                  key={execution.id}
                  rowNum={index + 1}
                  execution={execution}
                  columns={columns}
                  hiddenCols={hiddenCols}
                />
              ),
            )
          }
        </Tbody>
      </Table>
    </Box>
  );
};

const ExecutionRow = ({ rowNum, execution, columns, hiddenCols }: {
  rowNum: number,
  execution: WorkflowExecution,
  columns: Array<{ name: string }>
  hiddenCols: Array<string>
}) => {
  return (
    <Tr>
      <Td verticalAlign="top"><a target="_blank">{rowNum}</a></Td>
      {columns.map(column => (
        <Td
          key={column.name}
          verticalAlign="top"
        >
          {
            hiddenCols.includes(column.name)
              ? ''
              : (
                <Box
                  title={execution.testCase?.inputs[column.name]}
                  sx={{
                    display: '-webkit-box',
                    '-webkit-line-clamp': '16',
                    '-webkit-box-orient': 'vertical',
                    overflow: 'hidden',
                  }}
                >
                  <MagicValue value={execution.testCase?.inputs[column.name]} depth={3} />
                </Box>
              )
          }
        </Td>
      ))}
      <Td verticalAlign="top"><MagicValue minWidth="30em" value={execution.output} depth={3} /></Td>
    </Tr>
  );
};

const MagicValue = ({ value, depth, minWidth }: { value: any, depth: number, minWidth?: string }) => {
  if (!(value instanceof Object)) return value || null;

  if (depth <= 0) return JSON.stringify(value, null, 2);

  // We lay out arrays horizontally...
  if (Array.isArray(value)) {
    return (
      <Flex gap="1em">
        {value.map((item, index) => (
          <Box key={index} minWidth={minWidth}><MagicValue value={item} depth={depth - 1} minWidth={minWidth} /></Box>
        ))}
      </Flex>
    );
  }

  // ...and Objects as DLs
  const entries = Object.entries(value);
  // return JSON.stringify(entries, null, 2);
  return entries.map(
    ([k, v], index) => (
      <dl key={index}>
        <chakra.dt mt="1em" mb="0.5em" fontWeight="bold">{k}</chakra.dt>
        <chakra.dd><MagicValue value={v} depth={depth - 1} minWidth={minWidth} /></chakra.dd>
      </dl>
    ),
  );
};

export default CompareView;
