import PropTypes from 'prop-types';
import _ from "lodash";
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';

import Button from 'web-react-ui/src/components/elements/Button';
import Icon from 'web-react-ui/src/components/elements/Icon';
import View from 'web-react-ui/src/components/layout/View';
import WaitFor from 'web-react-ui/src/data/WaitFor';
import List from 'web-react-ui/src/components/elements/List';
import PagedList from 'web-react-ui/src/components/pagedList/PagedList';

import businessModule from '../../modules/businesses';
import client from '../../services/client';
import LocationsMap from '../locations/LocationsMap';

const LocationListItem = (props) => {
  const { location, business } = props;
  return (
    <List.Item as={Link} to={`/businesses/${business.id}/locations/${location.id}`}>
      <div className='flex aic'>
        <div className='fg1 fb0'>
          <List.Content>
            <List.Header>{location.name}</List.Header>
            <List.Description>{location.address.label}</List.Description>
          </List.Content>
        </div>
        <div className='fb0'>
          <List.Content>
            <Icon className="long arrow alternate right pull-right" />
          </List.Content>
        </div>
      </div>
    </List.Item>
  );
};

LocationListItem.propTypes = {
  location: PropTypes.object,
  business: PropTypes.object
};

class AdminBusinessLocationsView extends Component {
  static propTypes = {
    locations: PropTypes.object,
    property: PropTypes.object,
    business: PropTypes.object,
  };

  state = {
    showList: true,
    showMap: false
  };

  onListButtonClick = () => {
    this.setState({
      showList: true,
      showMap: false
    });
  };

  onMapButtonClick = () => {
    this.setState({
      showMap: true,
      showList: false
    });
  };

  handlePopupClick({ data }) {
    const { business } = this.props;
    return this.setState({ redirectTo: `/businesses/${business.id}/locations/${data.id}` });
  }

  fetchLocations() {
    const { business } = this.props;
    return client.businesses.for(business.id).locations.listAdmin();
  }

  render() {
    const {
      property, business, basePath
    } = this.props;

    const {
      showMap, showList, redirectTo, refetchId
    } = this.state;

    if (redirectTo) return <Redirect to={redirectTo} />;

    return (
      <View>
        <View.Section>
          <Button as={Link} to={`${basePath}/add`} floated='right'>Add Location</Button>
          <h1 className='mt0'>Locations</h1>
        </View.Section>
        <View.Section className='mt3'>
          <div className='flex'>
            <Button.Group className='pb1' style={{ marginLeft: 'auto' }}>
              <Button active={showMap} onClick={this.onMapButtonClick}>Map</Button>
              <Button active={showList} onClick={this.onListButtonClick}>List</Button>
            </Button.Group>
          </div>
          <PagedList fetchList={this.fetchLocations.bind(this)} params={{ refetchId }}>
            {({ items, loading }) => (
              <WaitFor waitFor={!loading}>
                {showList && (
                  <List celled selection>
                    {_.map(
                      items,
                      location => <LocationListItem
                        location={location}
                        property={property}
                        business={business}
                        key={location.id}
                      />
                    )}
                  </List>
                )}
                {showMap && (
                  <div className='mt4'>
                    <LocationsMap
                      locations={items}
                      property={property}
                      business={business}
                      onPopupClick={this.handlePopupClick.bind(this)}
                    />
                  </div>
                )}
              </WaitFor>
            )}
          </PagedList>
        </View.Section>
      </View>
    );
  }
}

AdminBusinessLocationsView.propTypes = {
  business: PropTypes.object,
  basePath: PropTypes.string
};

const mapState = state => ({
  business: businessModule.selectors.business.getData(state)
});

export default connect(mapState)(AdminBusinessLocationsView);
